import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["login"],
  endpoints: (builder) => ({
    postLogin: builder.mutation({
      query: (body) => ({
        url: "auth/login",
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["login"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
    }),
    postLoginGoogle: builder.mutation({
      query: (body) => ({
        url: "auth/login-with-google",
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["loginGoogle"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: "auth/changePassword",
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["changePassword"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.results,
    }),
    verifyEmail: builder.mutation({
      query: (code) => ({
        url: "auth/verifyEmail",
        method: "post",
        headers: authHeader(),
        params: { code },
      }),
      providesTags: ["verifyEmail"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status,
    }),
    getProfile: builder.mutation({
      query: () => ({
        url: "auth/profile",
        method: "get",
        headers: authHeader(),
      }),
      providesTags: ["getProfile"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `auth/forgotPassword?email=${body.email}`,
        method: "post",
        body,
      }),
      providesTags: ["forgotPassword"],
      transformResponse: (response) => response.results,
      // transformErrorResponse: (response) => response
    }),
  }),
})

export const {
  usePostLoginMutation,
  usePostLoginGoogleMutation,
  useChangePasswordMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useGetProfileMutation,
} = authApi
