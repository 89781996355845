import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  limit: 10,
  search: "",
}

export const listChatPagination = createSlice({
  name: "listChatPagination",
  initialState,
  reducers: {
    setParamChatPagination: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      }
      return state
    },
  },
})

export const { setParamChatPagination } = listChatPagination.actions

export default listChatPagination.reducer
