import NavbarNavigation from "./NavbarNavigation"
import FooterNavigation from "./FooterNavigation"
import DashboardNavigation from "./DashboardNavigation"

const basePath = "/seeker"

const navbar = NavbarNavigation
const footer = FooterNavigation(basePath)
const dashboard = DashboardNavigation

export {
  navbar,
  footer,
  dashboard
}
