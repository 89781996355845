import NavbarNavigation from "./NavbarNavigation"
import DashboardNavigation from "./DashboardNavigation"
import FooterNavigation from "./FooterNavigation"

const navbar = NavbarNavigation
const dashboard = DashboardNavigation
const footer = FooterNavigation

export {
  navbar,
  footer,
  dashboard
}
