import { User } from "react-feather"

export default [
  {
    id: 0,
    title: 'For Employers',
    icon: null,
    navLink: '/employer',
    isLogged: false,
    bg: '',
    textColor: ''
  },
  {
    id: 1,
    title: 'Jobs',
    icon: null,
    navLink: '/jobs',
    isLogged: false,
    bg: '',
    textColor: ''
  },
  {
    id: 2,
    title: 'About Us',
    icon: null,
    navLink: '/seeker/about-us',
    isLogged: false,
    bg: '',
    textColor: ''
  },
  {
    id: 3,
    title: 'My Dashboard',
    icon: <User />,
    navLink: '/seeker/dashboard',
    isLogged: true,
    bg: '',
    textColor: ''
  },
  {
    id: 4,
    title: 'My Dashboard',
    icon: <User />,
    navLink: '/employer/dashboard',
    isLogged: true,
    bg: '',
    textColor: ''
  },
  {
    id: 5,
    title: 'My Dashboard',
    icon: <User />,
    navLink: '/dashboard',
    isLogged: true,
    bg: '',
    textColor: ''
  },
  // {
  //   id: 5,
  //   title: 'Testimonials',
  //   icon: null,
  //   navLink: '/seeker/testimonials',
  //   isLogged: false,
  //   bg: '',
  //   textColor: ''
  // },
 
  // {
  //   id: 6,
  //   title: 'Contact Us',
  //   icon: null,
  //   navLink: '/seeker/contact-us',
  //   isLogged: false,
  //   bg: '',
  //   textColor: ''
  // },
  // {
  //   id: 7,
  //   title: 'Login',
  //   icon: null,
  //   navLink: '/login',
  //   isRight: true,
  //   isLogged: false,
  //   bg: '',
  //   textColor: ''
  // },
  // {
  //   id: 8,
  //   title: 'Register',
  //   icon: null,
  //   navLink: '/seeker/register',
  //   isRight: true,
  //   isLogged: false,
  //   bg: '',
  //   textColor: ''
  // },
  // {
  //   id: 9,
  //   title: 'Employer | Post Job',
  //   icon: null,
  //   navLink: '/employer',
  //   isRight: true,
  //   isLogged: false,
  //   bg: 'bg-primary',
  //   textColor: 'text-white'
  // },
  // {
  //   id: 10,
  //   title: 'Logout',
  //   icon: null,
  //   navLink: 'logout',
  //   isLogged: true,
  //   isRight: true,
  //   bg: '',
  //   textColor: ''
  // }
]
