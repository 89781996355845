import { createSlice } from "@reduxjs/toolkit" 

const initialState = {
  value: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    companyPostCode: '',
    companyPerfecture: '',
    companyDistrict: '',
    companyCity: '',
    companyAddress: '',
    companyJobIndustryId: null,
    companySize: null,
    companyPicFirstName: '',
    companyPicLastName: '',
    companyPhone: '',
    companyEmail: '',
    companyWebsite: '',
    companyDescription: '',
    termsCondition: false
  }
}

export const employerRegisterFormSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    changeEmployerRegisterForm: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      }
    },
    clearEmployerRegisterForm: (state) => {
      state.value = initialState.value
    }
  }
})

export const {
  changeEmployerRegisterForm,
  clearEmployerRegisterForm
} = employerRegisterFormSlice.actions

export default employerRegisterFormSlice.reducer
