// ** React Imports
import { Link } from "react-router-dom"

// ** Reactstrap Imports
import { Button, NavbarBrand } from "reactstrap"

// ** Custom Hooks
import { useSkin } from "@hooks/useSkin"

import logoLight from "@src/assets/images/logo/logo-new-icon.png"

// ** Utils
import {} from "@utils"

// ** Styles
import "@styles/base/pages/page-misc.scss"

const VerifyTokenError = ({ type = 404 }) => {
  // ** Hooks
  const { skin } = useSkin()

  const getChild = () => {
    if (type === 404) {
      return (
        <p className="mb-2">
          It looks like the link you are using has been used before.
          <br />
          Please login with the account you registered. If not, please register again
        </p>
      )
    } else {
      return (
        <p className="mb-2">
          Something went wrong, it seems something is wrong with our system, please try again later.
        </p>
      )
    }
  }

  const illustration = skin === "dark" ? "not-authorized-dark.svg" : "not-authorized.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default
  return (
    <div className="misc-wrapper">
      <NavbarBrand tag={Link} to="/" className="brand-logo">
        <div className="logo-header-inner logo-header-one">
          <img src={logoLight ?? logo} alt="Keihin" className="img-fluid w-25" />
        </div>
      </NavbarBrand>
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">Email verification failed</h2>
          {getChild()}
          <Button tag={Link} color="primary" className="btn-sm-block mb-1" to={"/login"}>
            Go to Login
          </Button>
          <img className="img-fluid" src={source} alt="Not authorized page" />
        </div>
      </div>
    </div>
  )
}
export default VerifyTokenError
