import React from "react"
import { Button, Col, Row } from "reactstrap"
import NoDataPng from "@src/assets/images/employer/candidates/nodata.png"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const NoData = () => {
  const navigate = useNavigate()
  const { width: widthScreen } = useWindowDimensions()
  const isMobile = widthScreen <= 768
  const { t } = useTranslation()

  return (
    <Row>
      <div className="p-1 w-auto">
        <img
          className={`rounded ${isMobile ? "h-324" : "h-163"} ratio ratio-1x1 m-auto`}
          style={{
            height: !isMobile ? 324 : 163,
            width: !isMobile ? 324 : 163,
            objectFit: "cover",
          }}
          src={NoDataPng}
          alt="No Data"
        />
      </div>
      <Col style={{ justifyContent: "space-evenly", display: "flex", flexDirection: "column" }}>
        <div className="flex flex-column">
          <text className={`font-bold ${isMobile ? "text-16 " : "text-32"}`}>
            {t("The Best Candidates Will Be Featured Soon")}
          </text>
          <text className={`font-bold ${isMobile ? "text-12 " : "text-24 "}`}>
            {t("Are you a seeker?")}
          </text>
          <text className={`font-bold ${isMobile ? "text-12 " : "text-24 "}`}>
            {t("Subscribe and your profile will be featured here")}
          </text>
        </div>
        <div>
          <button onClick={() => navigate("/subscription")} className="btn-subscribe">
            {t("Subscribe Now")}
          </button>
        </div>
      </Col>
    </Row>
  )
}

export default NoData
