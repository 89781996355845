import React from "react"
import image1 from "@src/assets/images/employer/features/image1.png"
import image3 from "@src/assets/images/employer/features/image3.png"
import image2 from "@src/assets/images/employer/features/image2.png"
import checklist from "@src/assets/icons/check-circle.svg"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import { useTranslation } from "react-i18next"

const Features = () => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isMobile = width <= 1024
  const feature1 = [
    {
      value:
        "Collaboration with the Indonesian government, with a focus on Southeast Asia's largest population.",
    },
    {
      value:
        "Introduction of young and diligent candidates who may be difficult to recruit among Japanese candidates.",
    },
    {
      value: "Immediate introduction of candidates even in urgent situations.",
    },
  ]

  const feature2 = [
    {
      value:
        "Our trained professionals offer accurate support in the native language of the foreign worker and in Japanese.",
    },
    {
      value:
        "We provide mandatory support for the 10 items required for Specified Skills Visa, as well as emergency support for injuries or illnesses.",
    },
    {
      value:
        "We prioritize a personal approach that values trust and communication with the workers.",
    },
  ]

  const feature3 = [
    {
      value:
        "Our specialized document processing team handles complicated paperwork on behalf of you.",
    },
    {
      value:
        "We also manage smooth communication between you and the selected candidate during the document processing.",
    },
    {
      value:
        "You can trust us to handle even the complicated procedures with the immigration office.",
    },
  ]
  return (
    <div className={`bg-gray-400 mt-10 ${isMobile ? "" : ""} `}>
      <div className="custom-container flex flex-col items-center justify-center w-full">
        <div className="text-24 font-bold mb-2 mt-2 align-center">
          {t("Strengthen your recruitment efforts with the latest features")}
        </div>
        <div
          className={`bg-white radius-md p-1 w-full ${
            isMobile ? "flex flex-col" : "flex items-start gap-x-8 justify-between"
          }`}
        >
          <img className={isMobile ? "w-full" : "w-50 img-stream"} src={image1} alt="Features" />
          <div className={`flex flex-col gap-y-2 ${!isMobile && "w-50"}`}>
            <div className={`${isMobile ? "text-20" : "text-lg"} font-bold `}>
              {t("Introduction of suitable candidates from our extensive talent database.")}
            </div>
            {feature1.map((item, index) => (
              <div className="flex items-center gap-x-4" key={index}>
                <img src={checklist} alt="checklist" />
                <div
                  style={{
                    textAlign: "start",
                    textJustify: "center",
                    verticalAlign: "middle",
                  }}
                  className="text-md font-light"
                >
                  {t(`${item.value}`)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`flex ${
            isMobile ? "flex-col gap-y-4" : "items-start justify-between "
          } gap-x-4 mt-1`}
        >
          <div
            style={{ minHeight: isMobile ? "auto" : "620px", height: isMobile ? "auto" : "760px" }}
            className={`radius-md bg-white p-1 ${
              isMobile ? "w-full flex flex-col-reverse" : "w-50"
            }`}
          >
            <div className="flex flex-col">
              <div className={`font-bold ${isMobile ? "text-20" : "text-lg"}`}>
                {t(
                  "We provide document processing services by experts in foreign talent employment."
                )}
              </div>
              <div
                className={`mt-2 flex ${isMobile ? "flex-col-reverse" : "flex-col"} gap-y-4 mb-2`}
              >
                {feature3.map((item, index) => (
                  <div className="flex items-center gap-x-4" key={index}>
                    <img src={checklist} alt="checklist" />
                    <div
                      style={{
                        textAlign: "start",
                        textJustify: "start",
                      }}
                      className="text-md font-light"
                    >
                      {t(`${item.value}`)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <img className="w-full mt-2 img-stream" src={image3} />
          </div>
          <div
            style={{ minHeight: isMobile ? "auto" : "620px", height: isMobile ? "auto" : "760px" }}
            className={`'radius-md bg-white p-1 radius-md mb-2 ${isMobile ? "w-full" : "w-50"}`}
          >
            <img className="w-full img-stream" src={image2} />
            <div className={`font-bold ${isMobile ? "text-20" : "text-lg"} `}>
              {t(
                "We provide support services as a designated support organization for Specified Skills Visa registration."
              )}
            </div>
            <div className="mt-2 flex flex-col gap-y-4 mb-2">
              {feature2.map((item, index) => (
                <div className="flex items-center gap-x-4" key={index}>
                  <img src={checklist} alt="checklist" />
                  <div
                    style={{
                      textAlign: "start",
                      textJustify: "start",
                    }}
                    className="text-md font-light"
                  >
                    {t(`${item.value}`)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
