import axios from "axios"


export const baseUrlConfig = {
  baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:3333/'
}

const http = () => axios.create(baseUrlConfig)

export const authHeader = () => {
  const token = localStorage.getItem('token')
  const headers = {
    // "access-control-allow-origin": '*',
    // origin: '*'
    mode: 'no-cors'
  }
  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  return headers
}

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) => async ({ url, method, body: data }) => {
    try {
      console.log(baseUrl + url)
      console.log(method)
      const result = await axios({ url: baseUrl + url, method, data })
      console.log(result)
      return { data: result.data }
    } catch (axiosError) {
      console.log(axiosError)
      const err = axiosError
      return {
        error: { status: err.response?.status, data: err.response?.data }
      }
    }
  }

export { http, axiosBaseQuery }
