import React, { useEffect } from "react"
// import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"

export default function EmptyPage() {
  const location = useLocation()
  // const { i18n } = useTranslation()

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement({
  //     pageLanguage: 'en',
  //     // layout: window.google.translate.TranslateElement.FloatPosition.BOTTOM_LEFT
  //   }, 'google_translate_element')
  // }

  useEffect(() => {
    // if (location.pathname === "/seeker" && !location.pathname.includes('seeker/register') && localStorage.getItem("i18nextLng") !== "id") i18n.changeLanguage("id")
    // if (location.pathname === "/employer" && !location.pathname.includes('employer/register') && localStorage.getItem("i18nextLng") !== "jp") i18n.changeLanguage("jp")
  }, [location])

  useEffect(() => {
    // const addScript = document.createElement('script')
    // addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
    // document.body.appendChild(addScript)
    // window.googleTranslateElementInit = googleTranslateElementInit
  }, [])

  return (
    <>
      <Outlet />
      <div id="google_translate_element"></div>
    </>
  )
}
