
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'
import { loggedInfo } from '@src/helpers/checkAuth';

// TO-DO: untuk keperluan check role saat ini
const currentRole = () => {
  const { role } = loggedInfo();
  switch (role) {
    case 'seeker':
      return 'seeker'
    case 'employer':
      return 'employer'
    case 'superadmin':
      return 'admin'
    default:
      return undefined
  }
}

export const chatHandle = createApi({
  reducerPath: 'chathandle',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["chathandle"],
  endpoints: (builder) => ({
    CreateRoom: builder.mutation({
      query: (roomId) => ({
        url: `${currentRole()}/chats`,
        method: 'post',
        headers: authHeader(),
        body: {
          subject: "",
          userId: roomId
        }
      }),
      providesTags: ['CreateRoom'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response
    }),
    SendMessage: builder.mutation({
      query: (payload) => ({
        url: `${currentRole()}/chats/${payload?.chatId}/messages`,
        method: 'post',
        headers: authHeader(),
        body: {
          message: payload?.message,
        }
      }),
      providesTags: ['SendMessage'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response
    }),
    GetMessage: builder.mutation({
      query: (roomId) => ({
        url: `${currentRole()}/chats/${roomId}/messages`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetMessage'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response
    }),
    GetContactChat: builder.mutation({
      query: (page = 1, perPage = 100) => ({
        url: `${currentRole()}/chats?page=${page}&perPage=${perPage}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetContact'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response
    }),
  })
})

export const {
  useCreateRoomMutation,
  useSendMessageMutation,
  useGetMessageMutation,
  useGetContactChatMutation
} = chatHandle
