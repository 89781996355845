import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"

const ModalAlert = ({ isOpen, title, children, onClick, closeText = "Cancel" }) => {
  return (
    <Modal 
      modalClassName="modal-green" 
      className='modal-dialog-centered'
      isOpen={isOpen} 
      title={title}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClick}>{closeText}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalAlert
