// export default [
//   {
//     id: 0,
//     title: 'Navigation',
//     children: [
//       {
//         id: 1,
//         title: 'Home',
//         url: '/employer'
//       },
//       {
//         id: 2,
//         title: 'About Us',
//         url: '/employer/about-us'
//       },
//       {
//         id: 3,
//         title: 'Contact Us',
//         url: '/employer/contact-us'
//       }
//       // {
//       //   id: 5,
//       //   title: 'ブログシングル',
//       //   url: 'blog-single.html'
//       // }
//     ]
//   },
//   {
//     id: 1,
//     title: '雇用主向け',
//     children: [
//       {
//         id: 1,
//         title: 'ポストジョブ',
//         url: 'dash-post-job.html'
//       },
//       {
//         id: 2,
//         title: 'ブロググリッド',
//         url: 'blog-grid.html'
//       },
//       {
//         id: 3,
//         title: 'コンタクト',
//         url: 'contact.html'
//       },
//       {
//         id: 4,
//         title: 'ジョブリスト',
//         url: 'job-list.html'
//       },
//       {
//         id: 5,
//         title: 'ジョブの詳細',
//         url: 'job-detail.html'
//       }
//     ]
//   }
//   // {
//   //   id: 2,
//   //   title: '役立つリソース',
//   //   children: [
//   //     {
//   //       id: 1,
//   //       title: 'FAQ',
//   //       url: 'faq.html'
//   //     },
//   //     {
//   //       id: 2,
//   //       title: '雇用主の詳細',
//   //       url: 'employer-detail.html'
//   //     },
//   //     {
//   //       id: 3,
//   //       title: 'プロフィール',
//   //       url: 'dash-my-profile.html'
//   //     },
//   //     {
//   //       id: 4,
//   //       title: '404ページ',
//   //       url: 'error-404.html'
//   //     },
//   //     {
//   //       id: 5,
//   //       title: '価格設定',
//   //       url: 'pricing.html'
//   //     }
//   //   ]
//   // },
//   // {
//   //   id: 3,
//   //   title: 'クイックリンク',
//   //   children: [
//   //     {
//   //       id: 1,
//   //       title: '家',
//   //       url: 'index.html'
//   //     },
//   //     {
//   //       id: 2,
//   //       title: '私たちに関しては',
//   //       url: 'about-1.html'
//   //     },
//   //     {
//   //       id: 3,
//   //       title: 'ブックマーク',
//   //       url: 'dash-bookmark.html'
//   //     },
//   //     {
//   //       id: 4,
//   //       title: '仕事',
//   //       url: 'job-grid.html'
//   //     },
//   //     {
//   //       id: 5,
//   //       title: '雇用者',
//   //       url: 'employer-list.html'
//   //     }
//   //   ]
//   // }
// ]


// import { useTranslation } from "react-i18next"
import fb from "@src/assets/images/icons/fb.svg"
import ig from "@src/assets/images/icons/ig.svg"
import tiktok from "@src/assets/images/icons/tiktok.svg"

export default function FooterNavigation(basePath) {
  // const {t} = useTranslation()
  return [
    {
      id: 0,
      title: 'Company',
      children: [
        {
          id: 1,
          title: 'About Us',
          url: `${basePath}/about-us`
        },
        {
          id: 2,
          title: 'For Job Seeker',
          url: '/seeker'
        },
        {
          id: 3,
          title: 'For Employers',
          url: '/employer'
        }
      ]
    },
    {
      id: 1,
      title: 'Account',
      children: [
        {
          id: 1,
          title: 'My Dashboard',
          url: `/employer/dashboard`
        },
        {
          id: 2,
          title: 'Login',
          url: '/login'
        },
        {
          id: 3,
          title: 'Sign Up',
          url: '/register-for'
        }
      ]
    },
    {
      id: 2,
      title: 'Job Type',
      children: [
        {
          id: 1,
          title: 'Par Time',
          url: `/jobs`
        },
        {
          id: 2,
          title: 'Full Time',
          url: '/jobs'
        },
        {
          id: 3,
          title: 'Contract Work',
          url: '/jobs'
        }
      ]
    },
    {
      id: 3,
      title: 'Follow Us',
      children: [
        {
          id: 1,
          title: <img src={fb} alt="Facebook" />,
          url: 'https://facebook.com/Mahajob.indonesia.jepang'
        },
        {
          id: 2,
          title: <img src={tiktok} alt="TikTok" />,
          url: 'https://tiktok.com/@mahajobdotcom'
        },
        {
          id: 3,
          title: <img src={ig} alt="Instagram" />,
          url: 'https://instagram.com/maha.job.matching'
        }
      ]
    }


  ]
}
