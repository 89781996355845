import React from 'react'

import { navbar as navbarData, footer as footerData } from "@src/navigation/landing-seeker"

import logo from '@src/assets/images/logo/logo.png'

import NavigationBar from "@shared/NavigationBar"
import Footer from "@shared/Footer"
import Hero from "@views/shared/Hero"
import { Container, ListGroup, ListGroupItem, Row, Col } from "reactstrap"

import { useTranslation } from 'react-i18next'

function PrivacyPolicy() {
  const { t } = useTranslation()
  return (
    <>
      <NavigationBar isNew={true} logo={logo} navigations={navbarData} />
      <Hero title={t('Privacy Policy')} />
      <div className="page-content m-tb100">
        <Container fluid="sm">
          <center><h2>{t('Privacy Policy')}</h2></center>
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-conditions">
                <div className="terms">
                  <p>
                    Maha Job values the privacy of information and personal data
                    of its users and will ensure obedience to security and confidentiality
                    standards related to all information and personal data submitted by
                    users through the Site and Maha Job.
                    <br />
                    <br />
                    It is very recommended for Users to read the privacy statement in a
                    careful manner to understand how Maha Job policies and
                    practices treat the information and personal data provided by Users on
                    the Site. This Privacy Statement will be applied to any Users either
                    registered or not, and provisions in the Privacy Statement could be
                    updated, revised, and have any variations and/or change from time to
                    time as may be considered needed and/or conformed by
                    Maha Job .
                  </p>
                  <p>
                    By registering to or using the services/ Site, or by clicking the
                    “Registration” button, User:
                  </p>
                  <ol type="a" className="list-desc">
                    <li>
                      Give permission to Maha Job for collecting, using,
                      disclosing and/or processing the User’s personal data as stated
                      below;
                    </li>
                    <li>
                      Give permission to Maha Job and for disclosing the User
                      personal data to provider or third party agent of maha-job.com
                      and the purpose as stated below.
                    </li>
                  </ol>
                  <br />
                  <h5>
                    <u>Purpose of Collection and Usage of Personal Data</u>
                  </h5>
                  <p>
                    While using Maha Job, Users could reveal or were requested
                    to give away information and/or personal data. To obtain the benefits
                    or using all services provided by the Site, Users may need to give
                    their information and/or personal data to Maha Job. Although
                    Users are not required to give away information and/or personal data
                    as requested on the Site, Maha Job would not be able to
                    provide certain services if the Users chose not to do so.
                  </p>
                  <br />
                  <h5>
                    <u>Collection of Personal Data</u>
                  </h5>
                  <p>
                    Maha Job values the privacy of information and personal data
                    of its users and will ensure the obedience of its staff toward the
                    tightest security and confidentiality standard regarding all
                    information and personal data which were given by users through the
                    Site and Maha Job, with subject to the provisions in this
                    Privacy Statement, and will not provide any information to anyone
                    without any permission from all Site related users (either registered
                    or not).
                    <br />
                    <br />
                    Permission to access the information and personal data of the users
                    are only given to the staff of Maha Job whom were given
                    authority as it should be.
                    <br />
                    Maha Job agreed to do all steps which can be implemented and
                    reasonable for keeping all information and personal data of users
                    permanently secret and/or not disclosed, conforming to these;
                    <br />
                    <br />
                    In general, Maha Job would only convey and/or transfer the
                    information and/or personal data of Users to the personnel and staff
                    of Maha Job to provide services to all users and customers
                    of Maha Job in form of companies who made special request
                    for information and/or data with the permission from the Users.
                    Despite so, Maha Job could express and/or transfer the
                    information and/or data to third parties on these circumstances:
                  </p>
                  <br />
                  <h5>
                    <u>Online Advertiser (Employer) and Applications </u>
                  </h5>
                  <p>
                    Making an online application for work advertisement on the Site is
                    free and optional that requires the users to fill the required column
                    (ex: Front Name, Family Name, Phone Number and E-mail address) so the
                    Advertiser could identify and contact the applicants. On the moment
                    you submit your work application on the Site, including attachments
                    and introduction letter :
                  </p>
                  <ul
                    className="list-desc"
                    style={{
                      listStyleType: "disc",
                      listStylePosition: "inside",
                      paddingLeft: 20
                    }}
                  >
                    <li>
                      will be forwarded in a manner directly to Advertiser and/or their
                      appointed representatives; and/or{" "}
                    </li>
                    <li>
                      will be stored in the Recruitment Center of Maha Job on
                      name of the Advertiser (Employer)
                    </li>
                  </ul>
                  <p>
                    Maha Job Recruitment Center allowed the Advertiser to easily
                    and effectively manage their recruitment process through one central
                    facility provided by Maha Job.
                    <br />
                    <br />
                    Information and data of the applicants are private-natured, Advertiser
                    (Employer) could use and access the information of the applicants
                    only for the sake of recruitment purpose for the advertised jobs and
                    not allowed for any other purposes. It is not allowed to do anything
                    outside of recruitment purpose, and if there are any existence of
                    violation, sanctions will be applied.
                    <br />
                    <br />
                    By applying some jobs position advertised on the Site, You asked for
                    Maha Job to forward your given information to the related
                    Advertiser and/or their appointed representatives. Every question you
                    have regarding the job position that advertised on Maha Job,
                    your online resume and/or application status will be showed directly
                    to the Advertiser or the appointed agent, not Maha Job.
                  </p>
                  <h5>
                    <u>Security</u>
                  </h5>
                  <p>
                    Information and personal data security of Users is the most important
                    thing for Maha Job. Maha Job will strive to ensure
                    that information and personal data of the Users will be protected from
                    any access without permission. Maha Job has been doing
                    action to maintain, protect and secure the information and personal
                    data of the User. <br />
                    <br />
                    All information and personal data provided by Users only could be
                    accessed by personnel who are given authority by Maha Job or
                    The third parties who is given authority by his party, and the
                    personnel will be instructed to obey provisions from this Privacy
                    Statement at the time of accessing personal information and data.
                    Users could be sure that personal information and data only will be
                    stored as needed for reach the aim of personal information and data
                    collection. Registered users should maintain their unique User Names
                    and Passwords and keep it a secret and not share the details with
                    anyone. <br />
                    <br />
                    Maha Job follow the accepted industry standard in a general
                    manner to protect information and personal data submitted by users to
                    Site, during the delivery or after the personal information and data
                    is accepted by Maha Job. Despite so, no there is no method
                    of delivery via the Internet, or electronics storage method, which are
                    100% safe. Because of that, Maha Job strive to protect the
                    information and personal data of the Users from any access without
                    permission, Maha Job could not ensure the absolute security
                    on this matter.{" "}
                  </p>
                  <h5>
                    <u>Question</u>
                  </h5>
                  <p>
                    If there are any question related to the Privacy Statement, please
                    send e-mail to{" "}
                    <a
                      style={{ color: "blue!important", textDecoration: "underline" }}
                      href="mailto: cs@maha-job.com"
                    >
                      cs@maha-job.com
                    </a>
                    {/* , call +62 21 6470 1243 or send us a letter at the following address:{" "} */}
                    <br />
                    <br />
                    Maha Job by MF Indonesia <br />
                    Kamata Building 202,<br />
                    5-27-18 Nishikamata,
                    <br />
                    Ota-ku,
                    <br />
                    Tokyo
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>


      <Footer logo={logo} navigations={footerData} />
    </>
  )
}

export default PrivacyPolicy
