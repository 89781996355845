import {
  Bell,
  Briefcase,
  Clipboard,
  Eye,
  File,
  Key,
  LogOut,
  ShoppingBag,
  User,
} from "react-feather"

export default [
  {
    title: "My Profile",
    icon: <User />,
    navLink: "/seeker/dashboard/profile",
  },
  {
    title: "My CV",
    icon: <File />,
    navLink: "/seeker/dashboard/resume",
  },
  {
    title: "My Subscriptions",
    icon: <ShoppingBag />,
    navLink: "/seeker/dashboard/subscribe",
  },
  {
    title: "Job Preferences",
    icon: <Clipboard />,
    navLink: "/seeker/dashboard/job-preferences",
  },
  {
    title: "Looking For New Job",
    icon: <Eye />,
    navLink: "/jobs",
  },
  {
    title: "Applied Jobs",
    icon: <Briefcase />,
    navLink: "/seeker/dashboard/applied-jobs",
  },
  {
    title: "Saved Jobs",
    icon: <Briefcase />,
    navLink: "/seeker/dashboard/saved-jobs",
  },

  {
    title: "Job Alerts",
    icon: <Bell />,
    navLink: "/seeker/dashboard/job-alerts",
  },
  {
    title: "Change Password",
    icon: <Key />,
    navLink: "/seeker/dashboard/change-password",
  },
  {
    title: "Logout",
    icon: <LogOut />,
    navLink: "/seeker/dashboard/logout",
  },
]
