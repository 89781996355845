import React from 'react'
import { useTranslation } from 'react-i18next'

const ChatBubble = ({ text, me }) => {
  const { t } = useTranslation()
  return (
    <div style={{ alignItems: 'flex-end', justifyContent: me ? 'flex-end' : 'flex-start' }} className="flex">
      <div style={{
        background: !me ? "#ffff" : "#F1DAF0",
        padding: 8,
        borderRadius: me ? "8px 0px 8px 8px" : "0px 8px 8px 8px",
        width: '70%',
      }}>{t(text)}</div>
    </div>
  )
}

export default ChatBubble