import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

const baseurl = 'seeker/education'

export const seekerEducationsApi = createApi({
  reducerPath: 'seeker/educations',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['SeekerEducation'],
  endpoints: (builder) => ({
    getEducations: builder.mutation({
      query: () => ({
        url: `${baseurl}`,
        method: 'GET',
        headers: authHeader()
      }),
      providesTags: ['GetAllJobCategories'],
      transformResponse: (response) => response.results
    }),
    getEducationsById: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'GET',
        headers: authHeader()
      }),
      providesTags: ['GetSingleJobCategories'],
      transformResponse: (response) => response.results
    }),
    postEducations: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: 'POST',
        headers: authHeader(),
        body
      }),
      providesTags: ['PostSingleJobCategories'],
      transformResponse: (response) => response.results
    }),
    updateEducations: builder.mutation({
      query: (data) => ({
        url: `${baseurl}/${data.id}`,
        method: 'PUT',
        headers: {
          ...authHeader(),
          contentType: 'multipart/form-data'
        },
        body: data.body
      }),
      providesTags: ['UpdateSingleJobCategories'],
      transformResponse: (response) => response.results
    }),
    deleteEducations: builder.mutation({
      query: (body) => ({
        url: `${baseurl}/${body.id}`,
        method: 'DELETE',
        headers: authHeader()
      }),
      providesTags: ['DeleteSingleJobCategories'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetEducationsMutation,
  useGetEducationsByIdMutation,
  usePostEducationsMutation,
  useUpdateEducationsMutation,
  useDeleteEducationsMutation
} = seekerEducationsApi
