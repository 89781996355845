import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig } from "../../helpers/http"

const baseUrl = '/job'

export const adminJobPostApi = createApi({
  reducerPath: 'jobPost',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: 'jobPost',
  endpoints: (builder) => ({
    getJobPost: builder.query({
      query: () => `${baseUrl}`
    })
  })  
})

export const {useGetJobPostQuery} = adminJobPostApi
