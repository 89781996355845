import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { authHeader, baseUrlConfig } from '../../helpers/http'

export const downloadJobExcell = createAsyncThunk('downloadJobExcell', async (jobId) => {
  const response = await axios.get(`${baseUrlConfig.baseUrl}job/${jobId}/export`, {
    headers: authHeader(),
    responseType: 'arraybuffer'
  })
  return response.data
})

const initialState = {
  file: null,
  loading: false,
  error: null
}
const jobExcellSlice = createSlice({
  name: 'job_file',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [downloadJobExcell.pending]: (state) => {
      state.loading = true
    },
    [downloadJobExcell.fulfilled]: (state, action) => {
      state.loading = false
      state.file = action.payload
    },
    [downloadJobExcell.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error
    }
  }
})

export const { reset } = jobExcellSlice.actions

export default jobExcellSlice.reducer
