import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

const baseurl = 'job/bookmark'

export const jobBookmarkApi = createApi({
  reducerPath: 'job/bookmark',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['JobBookmark'],
  endpoints: (builder) => ({
    getJobBookmark: builder.mutation({
      query: (query) => ({
        url: `${baseurl}`,
        method: 'get',
        headers: authHeader(),
        params: query
      }),
      providesTags: ['GetJobBookmark'],
      transformResponse: (response) => response.results
    }),
    getJobBookmarkById: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'get'
      }),
      providesTags: ['GetJobBookmarkByID'],
      transformResponse: (response) => response.results
    }),
    checkBookmarkedJob: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/check/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetAppliedJob'],
      transformResponse: (response) => response.results
    }),
    postJobBookmark: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'post',
        headers: authHeader()
      }),
      providesTags: ['PostJobBookmark'],
      transformResponse: (response) => response.results
    }),
    updateJobBookmark: builder.mutation({
      query(data) {
        const { id, ...body } = data
        return {
          url: `${baseurl}/${id}`,
          method: 'put',
          headers: authHeader(),
          body
        }
      },
      providesTags: ['UpdateJobBookmark'],
      transformResponse: (response) => response.results
    }),
    deleteJobBookmark: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'delete',
        headers: authHeader()
      }),
      providesTags: ['DeleteJobBookmark'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetJobBookmarkMutation,
  useGetJobBookmarkBySlugMutation,
  usePostJobBookmarkMutation,
  useCheckBookmarkedJobMutation,
  useUpdateJobBookmarkMutation,
  useDeleteJobBookmarkMutation
} = jobBookmarkApi
