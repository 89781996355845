
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'


export const employerProfileApi = createApi({
  reducerPath: 'employer/profile',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["employer"],
  endpoints: (builder) => ({
    getEmployerProfile: builder.mutation({
      query: () => ({
        url: 'employer/profile',
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['employer'],
      transformResponse: (response) => response.results
    }),
    getEmployerById: builder.mutation({
      query: (id) => ({
        url: `employer/profile/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['employer'],
      transformResponse: (response) => response.results
    }),
    updateEmployerProfile: builder.mutation({
      query: (body) => ({
        url: 'employer/profile',
        method: 'put',
        headers: authHeader(),
        body
      }),
      providesTags: ['employer'],
      transformResponse: (response) => response.results
    })
  })
})

export const { useUpdateEmployerProfileMutation, useGetEmployerProfileMutation, useGetEmployerByIdMutation } = employerProfileApi
