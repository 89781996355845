import React, { useCallback } from "react"
import "@src/assets/css/buttonGoogle.css"
import PropTypes from "prop-types"
import { GoogleIcon } from "./GoogleIcon"

export const ButtonGoogle = ({
    label = 'Sign in with Google',
    disabled = false,
    onClick
}) => {
    const click = useCallback((e) => {
        if (!disabled) {
            onClick(e)
        }
    }, [disabled])

    return (
        <div
            role="button"
            onClick={click}
            disabled
            className={`btn-google-sign ${disabled && 'btn-google-sign-disabled'}`}
        >
            <GoogleIcon />
            <span className="google-label">{label}</span>
        </div>
    );
}

ButtonGoogle.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.number,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['light', 'dark']),
    style: PropTypes.object
}
