// ** React Imports
import { Outlet } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/BlankLayout"

import "@src/assets/scss/landing.scss"

// ** Menu Items Array
// import navigation from "@src/navigation/horizontal"

const LandingLayout = () => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  )
}

export default LandingLayout
