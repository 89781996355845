
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'

export const employerAuthApi = createApi({
  reducerPath: 'employer/auth',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["employers"],
  endpoints: (builder) => ({
    employerLogin: builder.mutation({
      query: (body) => ({
        url: 'employer/auth/login',
        method: 'post',
        headers: authHeader(),
        body
      }),
      providesTags: ['employers'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status
    }),
    employerRegister: builder.mutation({
      query: (body) => ({
        url: 'employer/auth/register',
        method: 'post',
        headers: authHeader(),
        body
      }),
      providesTags: ['employers'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status
    }),
    employerRegisterWithEmailVerification: builder.mutation({
      query: (body) => ({
        url: 'employer/auth/newRegister',
        method: 'post',
        // headers: authHeader(),
        body
      }),
      providesTags: ['employers'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.data
    }),
    employerVerifyEmail: builder.mutation({
      query: (code) => ({
        url: 'employer/auth/verifyEmail',
        method: 'post',
        // headers: authHeader(),
        params: { code }
      }),
      providesTags: ['EmployerVerifyEmail'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status
    }),
    sendContactUs: builder.mutation({
      query: (body) => ({
        url: 'contact-us',
        method: 'post',
        body: { ...body }
      }),
      providesTags: ['SendContactUs'],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status
    })
  })
})

export const { useEmployerLoginMutation, useEmployerRegisterMutation, useEmployerVerifyEmailMutation, useEmployerRegisterWithEmailVerificationMutation, useSendContactUsMutation } = employerAuthApi
