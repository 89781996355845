import { Mail, Home, Settings } from "react-feather"

export default [
  {
    id: "home",
    title: "家",
    icon: <Home size={20} />,
    navLink: "/dashboard/home"
  },
  {
    id: "secondPage",
    title: "2番目のページ",
    icon: <Mail size={20} />,
    navLink: "/dashboard/second-page"
  },
  {
    id: "roleManagement",
    title: "ロール管理",
    icon: <Settings />,
    navLink: "/dashboard/role-management"
  }
]
