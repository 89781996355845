import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseurl = "seeker/jobPreferences"

export const seekerJobPreferencesApi = createApi({
  reducerPath: "seeker/jobPreferences",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["SeekerJobPreferences"],
  endpoints: (builder) => ({
    getJobPreferences: builder.mutation({
      query: (seekerProfileId) => ({
        url: `${baseurl}?seekerProfileId=${seekerProfileId}`,
        method: "GET",
        headers: authHeader(),
      }),
      providesTags: ["GetSeekerJobPreferences"],
      transformResponse: (response) => response,
    }),
    postJobPreferences: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: "POST",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostSingleSeekerJobPreferences"],
      transformResponse: (response) => response.results,
    }),
    deleteJobPreferences: builder.mutation({
      query: (body) => {
        return {
          url: `${baseurl}`,
          method: "DELETE",
          headers: authHeader(),
          body,
        }
      },
      providesTags: ["DeleteSingleSeekerJobPreferences"],
      transformResponse: (response) => response.results,
    }),
  }),
})

export const {
  useGetJobPreferencesMutation,
  usePostJobPreferencesMutation,
  useDeleteJobPreferencesMutation,
} = seekerJobPreferencesApi
