import React from "react"
import checklist from "@src/assets/icons/check-circle.svg"
import { useTranslation } from "react-i18next"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
// import { Button } from 'reactstrap'

const CardPrice = ({ title, items = [], currency, price, index, onClick = () => { } }) => {

  const { t } = useTranslation()
  const { width: widthScreen } = useWindowDimensions()
  const isMobile = widthScreen <= 1024
  return (
    <div onClick={onClick} style={{ marginBottom: index === 1 && !isMobile ? "8rem" : "" }} className={`${index === 1 ? "card-price-active" : "card-price"} flex flex-col gap-y-2`}>
      {
        index === 1 && <div style={{ alignSelf: 'flex-end', borderRadius: '8px' }} className="bg-pink-500 text-white w-50 flex items-center justify-center">{t("Most Popular")}</div>
      }
      <div className="text-lg font-black">{t(title)}</div>
      <div className="flex items-center">
        <div className="text-18 text-pink-500 font-black ">
          {currency}
        </div>
        <div className="text-lg text-pink-500 font-black">{price}</div>
      </div>
      <div className="flex-col flex">
        {items.map((item, index) => (
          <div key={index} className="flex items-center gap-x-4">
            <img src={checklist} alt="checklist" />
            <div className="text-md">{t(item)}</div>
          </div>
        ))}
      </div>
      <button className={`${index === 1 ? "btn-card-price-active" : "btn-card-price"}`}>{t("Choose Plan")}</button>
    </div>
  )
}

export default CardPrice
