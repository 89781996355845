import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Container, Input, InputGroup, Row, Spinner } from "reactstrap"
import { MessageSquare, Minimize2, Send, Mail } from "react-feather"
import { useLocation, useNavigate } from "react-router-dom"
import { FooterBody, HeaderBody, HeaderPeople, ListPeople } from "@src/components"
import Message from "@src/components/chat/Message"
import { useSelector, useDispatch } from "react-redux"
import { hideChat, showChat, onActiveChat } from "@store/chat/chatActions"
import { useGetMessageMutation } from "@src/redux/chat/chatHandle"
import { loggedInfo } from "@src/helpers/checkAuth"
import moment from "moment"
import { SocketContext } from "@src/utility/context/Socket"
import toast from "react-hot-toast"
import "@src/assets/css/chat.css"

import EmptyMessage from "@src/assets/images/chat/empty_message.jpg"
import NotificationSound from "@src/assets/sounds/notification-sound.aac"
import { useMediaQuery } from "usehooks-ts"
import Swal from "sweetalert2"
import { useGetSubscriptionsMutation } from "@src/redux/seeker/seekerSubscription"
import { getPhoto, renameContact } from "@src/utility/Utils"
import { setParamChatPagination } from "@src/redux/chat/listChatPagination"

export const ChatProvider = () => {
  const audioPlayer = useRef(null)
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const location = useLocation()
  const { token, role } = loggedInfo()
  const md = useMediaQuery("(max-width: 875px)")
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const listChatPagination = useSelector((state) => state.listChatPagination)

  const { isOpen, activeChat } = useSelector((state) => state.chatAction.value)
  const [GetMessage, dataHistory] = useGetMessageMutation()

  const [dataMessage, setDataMessage] = useState([])
  const [dataAllPeople, setDataAllPeople] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [onlineUsers, setOnlineUsers] = useState([])

  const { email } = loggedInfo()
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification))
  const playAudio = useCallback(() => {
    try {
      if (!isSafari) {
        audioPlayer.current && audioPlayer.current.play()
        audioElement.play()
      }
    } catch (error) {
      console.log("play sound err: ", error)
    }
  }, [audioPlayer])

  const ListContact = useMemo(() => {
    const resultData = dataAllPeople || []
    return resultData
  }, [dataAllPeople, listChatPagination])

  const detailActive = useMemo(() => {
    let data = activeChat
      ? dataAllPeople?.filter((i) => i.id === activeChat)
      : dataAllPeople.length > 0
      ? dataAllPeople[0]
      : []
    data = data.length > 0 ? data[0] : {}

    return {
      firstName:
        data?.members?.length > 0
          ? data?.members[0]?.company?.name || data?.members[0]?.firstName
          : "-",
      id: data?.members?.length > 0 ? data?.members[0]?.id : "-",
      photo: data?.members?.length > 0 ? data?.members[0]?.photo : null,
    }
  }, [dataAllPeople, activeChat])

  const messages = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let dataRev = []
    dataRev = dataRev.concat(dataMessage !== undefined ? dataMessage : [])
    return (
      dataRev.sort((a, b) => moment(a.createdAt).format("x") - moment(b.createdAt).format("x")) ||
      []
    )
  }, [dataMessage])

  const receiverId = useMemo(() => {
    const dataReceiver = ListContact || []
    const item = dataReceiver
      .filter((o) => o.id === activeChat)
      .map((itm) => {
        return itm?.members[0]?.id || null
      })
    return item.length > 0 ? item[0] : null
  }, [ListContact, activeChat])

  const isReceiverOnline = useMemo(() => {
    const data = onlineUsers || []
    return data.some((o) => o.id === receiverId)
  }, [onlineUsers, receiverId])

  const haveNewMessage = useMemo(() => {
    const newMsg =
      dataAllPeople.length > 0 ? dataAllPeople.filter((o) => o.isHasNewMessage === true) : []
    return newMsg.length || 0
  }, [dataAllPeople])

  const addMessage = useCallback(
    (message) => {
      if (!isOpen) {
        toast(message?.message || "", {
          position: "top-center",
          icon: <Mail />,
        })
      }
      if (message?.chatId === activeChat && isOpen) {
        GetMessage(activeChat).then(() => {
          socket.emit("messageOpened")
        })
      }
      playAudio()
    },
    [activeChat, isOpen]
  )

  const [getSubscriptions, { isLoading }] = useGetSubscriptionsMutation()

  useEffect(() => {
    if (role === "seeker") {
      getSubscriptions()
    }
  }, [])

  useEffect(() => {
    socket.on("getMessage", addMessage)
    return () => socket.off("getMessage", addMessage)
  }, [addMessage])

  useEffect(() => {
    socket.on("connect", () => {
      if (socket.connected) {
        console.log(socket.connected ? "connected" : "disconnect")
      }
    })

    socket.on("listChats", (data) => {
      if (data) {
        setDataAllPeople(data)
      }
    })

    socket.on("getOnlineUsers", (data) => {
      if (data) {
        setOnlineUsers(data)
      }
    })

    // handling error
    socket.on("disconnect", (reason) => {
      console.log("disconnect")
      if (reason === "io server disconnect") {
        socket.connect()
      }
    })

    socket.on("connect_error", () => {
      console.log("connect_error...")
    })

    socket.io.on("error", () => {
      console.log("error: ")
    })

    return () => {
      socket.off("connect")
      socket.off("listChats")
      socket.off("disconnect")
      socket.off("connect_error")
      socket.off("getOnlineUsers")
      socket.off("messageOpened")
    }
  }, [])

  useEffect(() => {
    if (dataHistory.data && dataHistory.data.length > 0) {
      setDataMessage(dataHistory.data)
    } else {
      setDataMessage([])
    }
  }, [dataHistory, activeChat])

  useEffect(() => {
    if (ListContact) {
      if (ListContact.length > 0 && !activeChat) {
        const roomId = ListContact[0].id
        dispatch(onActiveChat(roomId))
      }
    }
  }, [ListContact, activeChat])

  useEffect(() => {
    // selalu get data ketika ada perubahan active contact
    if (activeChat && isOpen) {
      GetMessage(activeChat).then(() => {
        socket.emit("messageOpened")
      })
    }
  }, [activeChat, isOpen])

  useEffect(() => {
    // berfungsi sbg perubahan pada component
    if (!token) {
      dispatch(hideChat())
      dispatch(onActiveChat(null))
      socket.disconnect()
    }

    // reconnect socket on reload
    if (token && socket.disconnected) {
      socket.connect()
    }

    return () => {
      socket.close()
    }
  }, [location, token, socket])

  const onOpenChat = useCallback(async () => {
    if (!token) {
      return Swal.fire({
        icon: "error",
        title: "Can't Access fitur chat",
        text: "Please login to access this feature!",
        confirmButtonText: "Login Now",
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/login?page=${pathname}`, { replace: true })
        }
      })
    }

    if (role === "seeker") {
      const res = await getSubscriptions()
      const isSubscribe = res?.data?.some((item) => item.status === "activated")
        ? "subscribe"
        : "unsubscribe"

      if (isSubscribe === "unsubscribe") {
        return Swal.fire({
          icon: "error",
          title: "Please Subscribe First",
          text: "Please Subscribe to access this feature!",
          confirmButtonText: "Subscribe Now",
          showCloseButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/subscription", { replace: true })
          }
        })
      }
    }

    dispatch(showChat(activeChat))
  }, [activeChat, token, role, pathname, navigate, dispatch])

  const onCloseChat = () => {
    dispatch(hideChat())
  }

  const onClickContact = useCallback(
    (e) => {
      const roomId = e.id
      setSelectedContact(roomId)
      if (activeChat !== roomId) {
        dispatch(onActiveChat(roomId))
        GetMessage(roomId).then(() => {
          socket.emit("messageOpened")
        })
      }
    },
    [activeChat]
  )

  const _onMessage = useCallback(
    (newMsg) => {
      setDataMessage([newMsg])
      const newArr = [...dataMessage]
      if (dataMessage.length > 0) {
        newArr.push(newMsg)
        setDataMessage(newArr)
      } else {
        setDataMessage([newMsg])
      }
    },
    [dataMessage]
  )

  const onSearchPeople = (name) => {
    dispatch(setParamChatPagination({ search: name, limit: 10 }))
    socket.emit("listChatsPagination", { search: name })
  }

  return (
    <div>
      <audio ref={audioPlayer} src={NotificationSound} />
      <div
        className={`body-chat
                shadow-lg
                rounded-3 border
                position-fixed 
                bottom-0 
                end-0
                mb-2
                mx-2
                bg-white
                p-2
                ${md ? "w-75" : "w-50"}
                
                `}
        style={{
          display: isOpen ? "block" : "none",
          transform: `scale(${isOpen ? 1 : 0})`,
          zIndex: 99,
        }}
      >
        <Row>
          {/* Mobile */}
          {md && !selectedContact && (
            <Col xs={100} className="d-flex flex-column px-0" style={{ height: "450px" }}>
              <HeaderPeople onChangeText={onSearchPeople} onClick={onCloseChat} isMobile={true} />
              <ListPeople data={ListContact} idActive={activeChat} onClick={onClickContact} />
            </Col>
          )}

          {/* Dekstop */}
          {!md && (
            <Col xs={4} className="d-flex flex-column border-end px-0">
              <HeaderPeople onChangeText={onSearchPeople} />
              <ListPeople data={ListContact} idActive={activeChat} onClick={onClickContact} />
            </Col>
          )}

          {/* TODO: Room Chat */}
          {/* Mobile */}
          {md && selectedContact && (
            <Col xs={12} className="px-0">
              <HeaderBody
                isMobile={true}
                onClick={onCloseChat}
                name={renameContact(detailActive?.firstName)}
                imgUrl={getPhoto(detailActive?.photo)}
                status={isReceiverOnline}
                onBackClick={() => setSelectedContact(null)}
              />
              {/* Message Room */}
              <div
                id="messageFeed"
                style={{
                  display: "flex",
                  position: "relative",
                  overflowY: "auto",
                  order: 2,
                  flexDirection: "column-reverse",
                  flexFlow: 1,
                  height: "22em",
                  scrollbarColor: "rgba(255,255,255, .16)",
                  scrollbarWidth: "thin",
                  verticalAlign: "baseline",
                  scrollBehavior: "smooth",
                }}
              >
                {dataHistory?.isLoading && (
                  <div
                    key={"asd"}
                    className="d-flex justify-content-center align-items-center my-3"
                  >
                    <Spinner color="primary" />
                  </div>
                )}

                <ol className="py-1 ul-message z-1">
                  {!dataHistory?.isLoading && messages.length > 0
                    ? messages
                        .map((item) => <Message key={item?.id} message={item} self={email} />)
                        .reduce((prev, next, currentIndex) => [
                          prev,
                          <div key={`cb-${currentIndex}`} className="mb-1" />,
                          next,
                        ])
                    : !dataHistory?.isLoading && (
                        <div key={"empty"} className="my-2 w-100 text-center">
                          <img src={EmptyMessage} width={152} />
                          <p className="text-secondary mt-1">Belum ada pesan.</p>
                        </div>
                      )}
                </ol>
              </div>

              {/* Footer Room */}
              <FooterBody
                roomId={activeChat}
                receiverId={receiverId}
                placeholder="Tulis pesan..."
                onMessage={_onMessage}
              />
            </Col>
          )}

          {/* Dekstop */}
          {!md && (
            <Col xs={8} className="px-0">
              <HeaderBody
                onClick={onCloseChat}
                name={detailActive?.firstName}
                imgUrl={getPhoto(detailActive?.photo)}
                status={isReceiverOnline}
              />
              {/* Message Room */}
              <div
                id="messageFeed"
                style={{
                  display: "flex",
                  position: "relative",
                  overflowY: "auto",
                  order: 2,
                  flexDirection: "column-reverse",
                  flexFlow: 1,
                  height: "22em",
                  scrollbarColor: "rgba(255,255,255, .16)",
                  scrollbarWidth: "thin",
                  verticalAlign: "baseline",
                  scrollBehavior: "smooth",
                }}
              >
                {dataHistory?.isLoading && (
                  <div
                    key={"asd"}
                    className="d-flex justify-content-center align-items-center my-3"
                  >
                    <Spinner color="primary" />
                  </div>
                )}

                <ol className="py-1 ul-message z-1">
                  {!dataHistory?.isLoading && messages.length > 0
                    ? messages
                        .map((item) => <Message key={item?.id} message={item} self={email} />)
                        .reduce((prev, next, currentIndex) => [
                          prev,
                          <div key={`cb-${currentIndex}`} className="mb-1" />,
                          next,
                        ])
                    : !dataHistory?.isLoading && (
                        <div key={"empty"} className="my-2 w-100 text-center">
                          <img src={EmptyMessage} width={152} />
                          <p className="text-secondary mt-1">Belum ada pesan.</p>
                        </div>
                      )}
                </ol>
              </div>

              {/* Footer Room */}
              <FooterBody
                roomId={activeChat}
                receiverId={receiverId}
                placeholder="Tulis pesan..."
                onMessage={_onMessage}
              />
            </Col>
          )}
        </Row>
      </div>

      {/* Button Floating */}
      {!isOpen && !isLoading && (
        <Button
          onClick={onOpenChat}
          color="primary"
          className="
                        btn-chat-float
                            shadow-lg 
                            round-5 
                            rounded-pill 
                            position-fixed 
                            bottom-0 
                            end-0
                            mb-4
                            mx-3
                            bg-white
                            "
          style={{ zIndex: 99 }}
        >
          <MessageSquare />
          &nbsp;&nbsp; Chat
          {haveNewMessage > 0 && (
            <div className="dot-new-msg">
              <span>{haveNewMessage}</span>
            </div>
          )}
        </Button>
      )}
    </div>
  )
}
