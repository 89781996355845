import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import { Swiper, SwiperSlide } from "swiper/react"
import { Col, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useGetBestCandidatesMutation } from "@src/redux/employer/candidate"
import NoData from "./NoData"
import CardCandidate from "./CardCandidate"

function JobsSection({ title = "" }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Row sm={1} md={2} lg={2} className="align-items-center">
      <Col>
        <span className="text-lg font-bold">{title}</span>
      </Col>
      <Col className="text-right">
        <button
          onClick={() => navigate("/candidate")}
          className="btn rounded-pill btn-header-purple-outline align-self-center"
        >
          {t("Browse All Candidates")}
        </button>
      </Col>
    </Row>
  )
}

const Candidates = () => {
  const { width: widthScreen } = useWindowDimensions()
  const swiperRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const { t } = useTranslation()

  const previous = () => {
    swiperRef.current.swiper.slidePrev()
  }

  const next = () => {
    swiperRef.current.swiper.slideNext()
  }

  const isMobile = widthScreen <= 1024
  const isLarge = widthScreen >= 1500

  const [getBestCandidates, { data = [], isLoading, isSuccess, reset }] =
    useGetBestCandidatesMutation()

  useEffect(() => {
    // getJobSeeker()
    getBestCandidates()
    return () => reset()
  }, [])

  const navigate = useNavigate()

  const goDetail = (seekerId) => {
    navigate(`/login`, { state: { seekerId } })
  }

  return (
    <div className="section-full p-b30 twm-how-it-work-area mt-2">
      <div className="custom-container">
        <JobsSection title={t("Best Candidates")} />
        <Swiper
          ref={swiperRef}
          initialSlide={activeIndex}
          onSlideChange={({ realIndex }) => setActiveIndex(realIndex)}
          spaceBetween={30}
          slidesPerView={isMobile ? 2 : isLarge ? 5 : 4}
          className="py-2"
        >
          {!isLoading && data?.length === 0 && <NoData />}
          {isLoading && <div className="text-center">Please wait . . .</div>}
          {isSuccess &&
            data?.map((item, index) => (
              <SwiperSlide key={index}>
                <CardCandidate
                  onClick={() => goDetail(item?.id)}
                  item={item}
                  image={item?.seekerProfile?.photo}
                />
              </SwiperSlide>
            ))}
          {widthScreen > 768
            ? data?.length > 7 && (
                <Row lg={2} className="justify-space-between w-100">
                  <Col className="align-self-center">
                    <div className="swiper-pagination">
                      {data
                        ?.slice(0, 10)
                        ?.map((_, index) => {
                          return (
                            <div
                              key={`pagi-${index}`}
                              className={`swiper-pagination-bullet ${
                                index === activeIndex ? "active" : ""
                              }`}
                            />
                          )
                        })
                        .reduce((prev, next, currIndex) => [
                          prev,
                          <div key={`pagix-${currIndex}`} style={{ width: 6 }} />,
                          next,
                        ])}
                    </div>
                  </Col>
                  <Col className="text-right px-0">
                    {data?.length > 2 && (
                      <>
                        <button onClick={previous} className="carousel-btn-control">
                          {"<"}
                        </button>
                        <button onClick={next} className="carousel-btn-control">
                          {">"}
                        </button>
                      </>
                    )}
                  </Col>
                </Row>
              )
            : data?.slice(0, 10)?.length > 2 && (
                <Row lg={2} className="justify-space-between w-100">
                  <Col className="align-self-center">
                    <div className="swiper-pagination">
                      {data
                        ?.slice(0, 10)
                        ?.map((_, index) => {
                          return (
                            <div
                              key={`pagi-${index}`}
                              className={`swiper-pagination-bullet ${
                                index === activeIndex ? "active" : ""
                              }`}
                            />
                          )
                        })
                        .reduce((prev, next, currIndex) => [
                          prev,
                          <div key={`pagix-${currIndex}`} style={{ width: 6 }} />,
                          next,
                        ])}
                    </div>
                  </Col>
                  <Col className="text-right px-0">
                    {data?.length > 2 && (
                      <>
                        <button onClick={previous} className="carousel-btn-control">
                          {"<"}
                        </button>
                        <button onClick={next} className="carousel-btn-control">
                          {">"}
                        </button>
                      </>
                    )}
                  </Col>
                </Row>
              )}
        </Swiper>
      </div>
    </div>
  )
}

export default Candidates
