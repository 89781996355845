// ** React Imports
import { Outlet, useLocation } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"

// // ** Core styles
// import '../@core/assets/fonts/feather/iconfont.css'
// import '../@core/scss/core.scss'
// import '../assets/scss/style.scss'

// ** Menu Items Array
import navigation from "@src/navigation/vertical"
import { useMemo } from "react";
import { protectPage } from "@src/helpers/checkAuth";

const VerticalLayout = (props) => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  const location = useLocation();
  // Access the current pathname
  const currentPathname = location.pathname;


  const cekPath = useMemo(() => {
    try {
      const isDashboard = currentPathname.split('/')[1];
      if (isDashboard === 'dashboard') {
        
        const currRole = 'superadmin';
        if (protectPage({ currentPath: currRole })) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } catch (error) {
      return true
    }
  }, [currentPathname])

  if (cekPath) {
    return (
      <Layout menuData={navigation} {...props}>
        <Outlet />
      </Layout>
    )
  } else {
    return (
      <Outlet />
    )
  }
}

export default VerticalLayout
