import React from "react"
// import bgEmployer from '@src/assets/images/employer/Hero.svg'
// import bgMobile from '@src/assets/images/employer/HeroMobile.svg'
import { useTranslation } from "react-i18next"
// import imagebg from '@src/assets/images/employer/imagebg.svg'
import imagebgweb from "@src/assets/images/employer/imagebgweb.png"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import { Link } from "react-router-dom"

const Banner = ({}) => {
  const { width } = useWindowDimensions()
  const isMobile = width < 768
  const isTab = width >= 768 && width <= 1024
  const isDesktop = width > 1024
  const color1 = "#633B9E"
  const color2 = "#A87CE9"
  const { t, i18n } = useTranslation()
  const isJapan = i18n.language === "jp"
  return (
    <section
      className={`relative section-full`}
      style={{
        marginLeft: isMobile ? 12 : "2rem",
        marginRight: isMobile ? 12 : "2rem",
        backgroundRepeat: "no-repeat",
        height: isMobile ? "600px" : "386px",
        marginTop: isMobile ? "80px" : "90px",
        padding: isMobile ? "20px 0px 0px 0px" : "2rem",
        marginBottom: "0",
        borderRadius: "32px",
        background: isMobile
          ? `linear-gradient(to bottom,  ${color1} 0%,${color2} 180%)`
          : `linear-gradient(to right,  ${color1} 0%,${color2} 180%)`,
      }}
    >
      <div className={`mx-2 flex items-start justify-start`}>
        <div
          className={`z-index-20 ${
            !isDesktop ? "flex flex-col w-full items-start justify-start" : "w-75"
          }`}
        >
          <div
            className={
              isMobile
                ? "bg-color-pink-200 text-white banner-pink text-small align-center w-full"
                : "bg-color-pink-200 text-white banner-pink text-small w-58 text-24"
            }
          >
            {t("To companies considering employing foreign workers")}
          </div>

          <div
            className={
              isMobile ? "" : isTab ? "" : `flex h-full gap-2 w-full ${isJapan ? "mt-12" : "mt-2"}`
            }
          >
            <div
              className={`flex flex-col justify-start ${isTab && "mt-5"} ${
                isDesktop ? "w-45" : "w-full"
              }`}
            >
              <span
                style={{ textAlign: isMobile ? "center" : "left" }}
                className={`text-white w-full ${
                  isMobile ? "text-32" : isTab ? "text-lg " : "text-42 "
                } font-bold  text-center`}
              >
                {t("“Foreign human resources”")}
              </span>
              <span
                style={{ textAlign: isMobile ? "center" : "left" }}
                className={`text-white w-full ${
                  isMobile ? "text-32" : isTab ? "text-lg" : "text-42  text-center"
                } font-bold`}
              >
                {t("Relieve the industry's labor shortage")}
              </span>
            </div>

            <div className={`${isDesktop ? "mt-1 w-39" : "w-full"}`}>
              <div className={`text-20 text-white text-center w-full`}>
                {t("Corresponding to various residency statuses such as specified skilled workers")}
              </div>
              <Link
                to="/employer/register"
                style={{ alignSelf: "center" }}
                className={`text-black text-20 font-bold w-auto mb-4 register-btn`}
              >
                {t("Register Your Company")}
              </Link>
            </div>
          </div>
        </div>
        <img
          style={{
            height: isMobile ? 220 : isTab ? 240 : 340,
            bottom: 1,
            width: isMobile ? 280 : "",
          }}
          className={`absolute  ${isMobile ? "right-12" : "bottom-0 right-10"}`}
          src={imagebgweb}
          alt="imagebg"
        />
      </div>
    </section>
  )
}

export default Banner
