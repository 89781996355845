import React from "react"

import bannerBg from "@src/assets/images/banner/1.jpg"
import { Link } from "react-router-dom"
import { Container, List } from "reactstrap"

import { useTranslation } from 'react-i18next'

export default function Hero({ title }) {
  const { t } = useTranslation()
  return (
    <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: `url(${bannerBg})`, height: '250px' }}>
      <div className="overlay-main site-bg-white opacity-01"></div>
      <Container>
        <div className="wt-bnr-inr-entry">
          <div className="banner-title-outer">
            <div className="banner-title-name">
              <h2 className="wt-title" style={{ width: '312px' }}>{title}</h2>
            </div>
          </div>
          <div>
            <List type="unstyled" className="wt-breadcrumb breadcrumb-style-2">
              <li><Link style={{ color: "inherit" }}>{t('homeAboutUs')}</Link></li>
              <li className="text-black">{t(title)}</li>
            </List>
          </div>
        </div>
      </Container>
    </div>
  )
}
