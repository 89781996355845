import { createSlice } from "@reduxjs/toolkit"

const basicInformation = {
  title: '',
  jobTypeId: '',
  jobCategoryId: '',
  jobIndustryId: '',
  validDate: '',
  numberOfHires: 0,
  statusOfResidence: '',
  basicSalary: 0,
  feeMaximum: 0,
  gender: 0,
  minAge: 0,
  maxAge: 0,
  workplace: '',
  description: '',
  picFirstName: '',
  picLastName: '',
  picEmail: '',
  picPhone: ''
}

const jobPostCondition = {
  // allowance
  allowance1: '',
  monthly1: '',
  allowance2: '',
  monthly2: '',
  allowance3: '',
  monthly3: '',
  // wage
  wageDeadlineType: '',
  wageDeadlineDay: 0,
  wageDatePayment: '',
  wagePayment: 0,
  cmtAllowance: '',
  cmtAllowancePaymentDate: '',
  bonusSalary: 0,
  salaryIncrease: 0,
  isEmpIns: false,
  isHealtInsurance: false,
  isWorkAccIns: false,
  isWalfarePension: false,
  isOtherEnrollIns: false,
  otherEnrollInsurance: '',
  // workingHours
  working1: '',
  workingRest1: '',
  actualWork1: '',
  working2: '',
  workingRest2: '',
  actualWork2: '',
  working3: '',
  workingRest3: '',
  actualWork3: '',
  // lembur
  isOvertime: false,
  rateOvertime: 0,
  isLateNight: false,
  rateLateNight: 0,
  averageHourMonth: 0,
  averageDayMonth: 0,
  // holiday
  holiday: 0,
  dayOfWeek: 0,
  other: '',
  nationalHoliday: 0,
  photo: null,
  workPhoto1: null,
  workPhoto2: null,
  workPhoto3: null,
  workPhoto4: null
}

const jobPostDetail = {
  occupation: '',
  businessDivision: '',
  manufacturingField: '',
  businessContent: '',
  employmentStatus: '',
  workStyle: '',
  employmentPeriod: '',
  periodUpdate: '',
  experienceRequired: '',
  japanaseRequiredLevel: '',
  necessaryQualification: '',
  companyMessage: '',
  corporationMessage: ''
}

const jobHousing = {
  buildingName: '',
  address: '',
  nearestStation: '',
  commutingTime: '',
  floorPlan: 1,
  residentPerson: '',
  toilet: '',
  bath: '',
  preparationMoney: '',
  wifi: '',
  mealSupport: '',
  uniformLoan: '',
  suroundingFacilities: '',
  floorPlanPhoto: null,
  roomPhoto: null,
  bathPhoto: null,
  kitchenPhoto: null
}

const initialState = {
  value: {
    basicInformation,
    jobPostCondition,
    jobPostDetail,
    jobHousing
  }
}

export const postJobFormSlice = createSlice({
  name: 'post_job',
  initialState,
  reducers: {
    setInitialData: (state, action) => {
      state.value = action.payload
      // state.value = {
      //   ...state.value,
      //   ...action.payload
      // }
    },
    changeBasicInformation: (state, action) => {
      state.value.basicInformation = {
        ...state.value.basicInformation,
        ...action.payload
      }
    },
    changeJobPostCondition: (state, action) => {
      state.value.jobPostCondition = {
        ...state.value.jobPostCondition,
        ...action.payload
      }
    },
    changeJobPostDetail: (state, action) => {
      state.value.jobPostDetail = {
        ...state.value.jobPostDetail,
        ...action.payload
      }
    },
    changeJobHousing: (state, action) => {
      state.value.jobHousing = {
        ...state.value.jobHousing,
        ...action.payload
      }
    },
    clearBasicInformation: (state) => {
      state.value.basicInformation = basicInformation
    },
    clearJobPostCondition: (state) => {
      state.value.jobPostCondition = jobPostCondition
    },
    clearJobPostDetail: (state) => {
      state.value.jobPostDetail = jobPostDetail
    },
    clearJobHousing: (state) => {
      state.value.jobHousing = jobHousing
    },
    clearJobPostForm: (state) => {
      state.value = initialState.value
    }
  }
})

export const {
  changeBasicInformation,
  changeJobPostCondition,
  changeJobPostDetail,
  changeJobHousing,
  clearBasicInformation,
  clearJobHousing,
  clearJobPostDetail,
  clearJobPostCondition,
  clearJobPostForm,
  setInitialData
} = postJobFormSlice.actions

export default postJobFormSlice.reducer
