import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseurl = "job/type"

export const jobTypeApi = createApi({
  reducerPath: "job/type",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["JobType"],
  endpoints: (builder) => ({
    getJobType: builder.mutation({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: "get",
      }),
      providesTags: ["GetJobType"],
      transformResponse: (response) => response.results,
    }),
    getJobTypeBySlug: builder.mutation({
      query: (slug) => ({
        url: `${baseurl}/${slug}`,
        method: "get",
      }),
      providesTags: ["GetJobTypeBySlug"],
      transformResponse: (response) => response.results,
    }),
    postJobType: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostJobType"],
      transformResponse: (response) => response.results,
    }),
    updateJobType: builder.mutation({
      query(data) {
        const { id, ...body } = data
        return {
          url: `${baseurl}/${id}`,
          method: "put",
          headers: authHeader(),
          body,
        }
      },
      providesTags: ["UpdateJobType"],
      transformResponse: (response) => response.results,
    }),
    deleteJobType: builder.mutation({
      query: ({ id }) => ({
        url: `${baseurl}/${id}`,
        method: "delete",
        headers: authHeader(),
      }),
      providesTags: ["DeleteJobType"],
      transformResponse: (response) => response.results,
    }),
  }),
})

export const {
  useGetJobTypeMutation,
  useGetJobTypeBySlugMutation,
  usePostJobTypeMutation,
  useUpdateJobTypeMutation,
  useDeleteJobTypeMutation,
} = jobTypeApi
