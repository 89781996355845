import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig, authHeader } from "../../helpers/http"

const baseUrl = 'admin/changePassword'

export const changePasswordApi = createApi({
    reducerPath: "admin/changePassword",
    baseQuery: fetchBaseQuery(baseUrlConfig),
    tagTypes: ['admin/changePassword'],
    endpoints: (builder) => ({
        postChangePassword: builder.mutation({
            query: (body) => ({
                url: `${baseUrl}`,
                method: 'post',
                headers: authHeader(),
                body
            }),
            providesTags: ['admin/changePassword'],
            transformResponse: (response) => response.results
        })
    })
})

export const {
    usePostChangePasswordMutation
} = changePasswordApi
