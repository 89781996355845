
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const postalApi = createApi({
  reducerPath: 'postal',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.JPN_POSTAL_API_URL || 'https://zipcloud.ibsnet.co.jp/api/'
  }),
  tagTypes: ["postals"],
  endpoints: (builder) => ({
    getPostal: builder.mutation({
      query: (zipcode) => ({
        url: `search?zipcode=${zipcode}`,
        method: 'get'
        // headers: authHeader()
      }),
      providesTags: ['postals'],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response.status
    })
  })
})

export const { useGetPostalMutation } = postalApi
