import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

export const subscriptionsApi = createApi({
    reducerPath: "subscriptions",
    baseQuery: fetchBaseQuery(baseUrlConfig),
    tagTypes: ["subscriptions"],
    endpoints: (builder) => ({
        getSubscriptions: builder.mutation({
            query: () => ({
                url: "subscriptions/prices",
                method: "get",
                headers: authHeader(),
            }),
            transformResponse: (response) => response.results,
        }),
    }),
})

export const { useGetSubscriptionsMutation } = subscriptionsApi