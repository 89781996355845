// ** Third Party Components

import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next"
import { isMobile } from 'react-device-detect';

// ** Reactstrap Imports
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"

import Iconglobe from "@src/assets/images/globe.svg";

const IntlDropdown = () => {
  // ** Hooks
  const { i18n } = useTranslation()

  // ** Vars
  const langObj = {
    en: "EN",
    id: "ID",
    de: "GR",
    fr: "FR",
    pt: "PG",
    jp: "JP"
  }

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
    i18n.changeLanguage(lang)
  }

  return (
    <UncontrolledDropdown
      href="/"
      tag="div"
      style={{ borderLeft: '1px solid #fff' }}
      className="dropdown-language nav-item"
    >
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link"
        onClick={(e) => e.preventDefault()}
      >
        {/* <ReactCountryFlag
          svg
          className="country-flag flag-icon me-1 justify-content-center"
          countryCode={i18n.language === "en" ? "us" : i18n.language}
        /> */}
        <img
          src={Iconglobe}
          alt="globe"
          style={{ width: '16px' }}
        />
        <span className={`nav-lang-label ${isMobile ? "bold" : ""}`}>
          {langObj[i18n.language]}
        </span>
      </DropdownToggle>
      <DropdownMenu end style={{
        borderRadius: '14px',
        padding: '0px 0px',
        overflow: 'hidden',
        border: '2px solid #ddd',
      }}>
        <DropdownItem
          href="/"
          tag="a"
          className="dropdown-item-lang"
          onClick={(e) => handleLangUpdate(e, "id")}
        >
          <ReactCountryFlag className="country-flag rounded" countryCode="id" svg />
          <span className="ms-1">Indonesia</span>
        </DropdownItem>
        <DropdownItem
          href="/"
          tag="a"
          className="dropdown-item-lang"
          onClick={(e) => handleLangUpdate(e, "jp")}
        >
          <ReactCountryFlag className="country-flag rounded" countryCode="jp" svg />
          <span className="ms-1">Japan</span>
        </DropdownItem>
        <DropdownItem
          href="/"
          tag="a"
          className="dropdown-item-lang"
          onClick={(e) => handleLangUpdate(e, "en")}
        >
          <ReactCountryFlag className="country-flag rounded" countryCode="us" svg />
          <span className="ms-1">English</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
