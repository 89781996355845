import * as dotenv from "dotenv"
dotenv.config()

export default {
  apiImageResourceURL: process.env.REACT_APP_FILE_URL ? `${process.env.REACT_APP_FILE_URL}assets/uploads/` : "http://localhost:3333/public/",
  apiEndpointURL: process.env.REACT_APP_API_URL ?? "http://localhost:3333/",
  listAvailableImage: [
    "agriculture.jpg",
    "building-cleaning-management.jpg",
    "car-repair-and-maintenance.jpg",
    "construction.jpg",
    "electricity-electronics-and-information.jpg",
    "fisheries-and-aquatic-cultivation.jpg",
    "flight.jpg",
    "food-and-beverage-production.jpg",
    "food-service.jpg",
    "hospitality.jpg",
    "industrial-machinery.jpg",
    "machine-and-equipment-components.jpg",
    "nursing.jpg",
    "shipbuilder.jpg",
  ],
}
