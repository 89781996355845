import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig, authHeader } from "@src/helpers/http"

export const seekerJapanExperienceApi = createApi({
  reducerPath: "seeker/japanExperiences",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["Seekers"],
  endpoints: (builder) => ({
    getJapanExperiences: builder.mutation({
      query: () => ({
        url: "seeker/japan-experiences",
        method: "GET",
        headers: authHeader()
      }),
      providesTags: ["SeekersJapanExperiences"],
      transformResponse: (response) => response.results
    }),
    addJapanExperiences: builder.mutation({
      query: ({ body }) => ({
        url: "seeker/japan-experiences",
        method: "POST",
        headers: {
          ...authHeader(),
          "Content-Type": "application/json"
        },
        body
      }),
      providesTags: ["SeekersJapanExperiencePost"],
      transformResponse: (response) => response.result
    }),
    updateJapanExperience: builder.mutation({
      query: ({ id, body }) => ({
        url: `seeker/japan-experiences/${id}`,
        method: 'PUT',
        headers: authHeader(),
        body
      }),
      providesTags: ['SeekersJapanExperienceUpdate'],
      transformResponse: (response) => response.result
    }),
    deleteJapanExperience: builder.mutation({
      query: ({ id }) => ({
        url: `seeker/japan-experiences/${id}`,
        method: 'DELETE',
        headers: authHeader()
      }),
      providesTags: ['SeekersJapanExperienceDelete'],
      transformResponse: (response) => response.result
    })
  })
})

export const { useGetJapanExperiencesMutation, useAddJapanExperiencesMutation, useUpdateJapanExperienceMutation, useDeleteJapanExperienceMutation } = seekerJapanExperienceApi
