import NavigationBar from "@shared/NavigationBar"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { footer as footerData, navbar as navigations } from "@src/navigation/landing-seeker"
import Footer from "@shared/Footer"
import Hero from "@shared/Hero"
import eye from "@src/assets/icons/eye.svg"
import photo from "@src/assets/images/employer/candidates/img.png"
import checklist from "@src/assets/icons/check-circle.svg"
import directchat from "@src/assets/icons/direct-chat.svg"
import ChatBubble from "../components/ChatBubble"
import { useTranslation } from "react-i18next"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import CardPrice from "../components/CardPrice"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  useCreatePendingSubscriptionMutation,
  useGetSubscriptionsMutation,
} from "@src/redux/subscriptions/subscriptionPrices"
import { useNavigate, useLocation } from "react-router-dom"
import CardCandidate from "../components/CardCandidate"
import toast from "react-hot-toast"
import { intToCurrency } from "@src/utility/carrency"

import img2 from "@src/assets/images/employer/candidates/img2.png"
import img3 from "@src/assets/images/employer/candidates/img3.png"
import img4 from "@src/assets/images/employer/candidates/img4.png"
import img5 from "@src/assets/images/employer/candidates/img5.png"
import img6 from "@src/assets/images/employer/candidates/img6.png"
import { Row } from "reactstrap"

const Subscription = () => {
  const { pathname } = useLocation()
  const { i18n, t } = useTranslation()

  const [subscriptionPackage, setSubscriptionPackage] = useState([])
  const [getSubscriptionPrices] = useGetSubscriptionsMutation()
  const [createPendingSubscription] = useCreatePendingSubscriptionMutation()

  const pricing = useCallback(
    (price) => {
      const usd = price?.usd || 0
      const idr = price?.idr || 0
      const jpy = price?.jpy || 0

      switch (i18n.language) {
        case "en":
          return `$-${Math.round(usd)}`
        case "id":
          return `Rp-${intToCurrency(idr)}`
        default:
          return `JPY-${Math.round(jpy)}`
      }
    },
    [i18n.language]
  )

  useEffect(() => {
    getSubscriptionPrices()
      .then((data) => {
        setSubscriptionPackage(data?.data)
      })
      .catch((err) => console.log({ err }))
  }, [])

  const navigate = useNavigate()

  const handlePendingSubscription = useCallback(
    (body) => {
      const token = localStorage.getItem("token")

      if (!token) {
        navigate(`/login?page=${pathname}`)
      } else {
        createPendingSubscription(body).then((data) => {
          if (data?.error?.data?.message) {
            toast.error(data?.error?.data?.message)
          }

          if (data?.data) {
            navigate("/seeker/cart", { state: data?.data || {} })
          }
        })
      }
    },
    [pathname]
  )

  const premiumData = [
    {
      item: `Your profile will be prominently displayed on the employer's 'Best Candidate' page. This puts you in front of top hiring managers actively seeking qualified candidates.`,
    },
    {
      item: `Stand out from the competition with a highlighted profile and attract the attention of potential employers who may not have otherwise seen your qualifications.`,
    },
    {
      item: `Don't leave your job search to chance. Increase your visibility with a Premium Membership and give yourself the best opportunity to secure interviews and job offers.`,
    },
  ]

  const directData = [
    {
      item: "Initiate direct conversations with potential employers. Show your interest and enthusiasm by reaching out directly.",
    },
    {
      item: "Chat directly with employers to clarify any questions about the job position and highlight relevant skills or experience that may not be fully captured in your profile.",
    },
    {
      item: "Get priority access to our dedicated support team. They'll be available to answer your questions and assist you throughout your job search journey.",
    },
  ]

  const candidates = [
    {
      firstName: "Ayu",
      lastName: "Kusumawardani",
      qualification: "FRESH GRADUATE",
      qualification2: "Software Engineer",
      city: "Jakarta Selatan, Indonesia",
      image: photo,
    },
    {
      firstName: "Made",
      lastName: "Putra Wijaya",
      qualification: "MID-LEVEL",
      qualification2: "TG Restoran",
      city: "Denpasar, Bali, Indonesia",
      image: img2,
    },
    {
      firstName: "Muhammad",
      lastName: "Reza",
      qualification: "FRESH GRADUATE",
      qualification2: "Customer Service",
      city: "Pati, Jawa Tengah, Indonesia",
      image: img3,
    },
    {
      firstName: "Ayu",
      lastName: "Kusumawardani",
      qualification: "FRESH GRADUATE",
      qualification2: "Software Engineer",
      city: "Jakarta Selatan, Indonesia",
    },
  ]
  const candidates2 = [
    {
      firstName: "Isyani",
      lastName: "Rahayu",
      qualification: "MID-LEVEL",
      qualification2: "TG Kaigo",
      city: "Blitar, Indonesia",
      image: img4,
    },
    {
      firstName: "M. Agus",
      lastName: "Rasyid",
      qualification: "TRAINE",
      qualification2: "Software Engineer",
      city: "Solo, Indonesia",
      image: img5,
    },
    {
      firstName: "Nindia",
      lastName: "Listiani",
      qualification: "FRESH GRADUATE",
      qualification2: "Software Engineer",
      city: "Jakarta Selatan, Indonesia",
      image: img6,
    },
    {
      firstName: "Ayu",
      lastName: "Kusumawardani",
      qualification: "FRESH GRADUATE",
      qualification2: "Software Engineer",
      city: "Jakarta Selatan, Indonesia",
    },
  ]

  const { width } = useWindowDimensions()
  const isMobile = width <= 1024
  const isLarge = width >= 1500

  const swiperRef = useRef(null)
  return (
    <div className="page-wrapper">
      <NavigationBar logo={""} isNew={true} navigations={navigations} />
      <div className="page-content">
        <Hero title={"Subscription"} />
        <div className="section-full p-t120 p-b90 bg-gray-200">
          <div className="custom-container">
            <Row xs={1} className="gap-y-1">
              <div
                className={`${
                  isMobile ? "w-full" : "w-50"
                } flex flex-col items-start justify-start`}
              >
                <div className={`text-lg font-bold h-28  ${isMobile ? "w-full" : "w-70"} mb-8`}>
                  {t(`Unlock your job search potential with a premium membership`)}
                </div>
                <div className="bg-white mt-10 radius-md p-2 w-full">
                  <div className="flex items-center justify-start gap-x-4 w-full">
                    <img src={eye} />
                    <div className="text-lg font-bold">{t("Premium Profile Visibility")}</div>
                  </div>
                  {premiumData.map((item, index) => (
                    <div key={index} className="flex items-start mt-2 justify-start gap-x-4">
                      <img style={{ marginTop: 8 }} src={checklist} />
                      <div className="text-md">{t(item.item)}</div>
                    </div>
                  ))}

                  <div className="bg-gray-200 mt-10 radius-md p-1 subscirption-card">
                    <div className="text-20 font-bold">{t("Best Candidates")}</div>

                    <Swiper
                      slidesPerView={isMobile ? "1.5" : isLarge ? "3.5" : "2"}
                      style={{ paddingTop: 4, paddingBottom: 4 }}
                      className="py-1"
                      ref={swiperRef}
                    >
                      {candidates.map((item, index) => (
                        <SwiperSlide key={index} className="me-2">
                          <CardCandidate item={item} image={item.image} />
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    <Swiper
                      slidesPerView={isMobile ? "1.5" : isLarge ? "3.5" : "2"}
                      style={{ paddingTop: 4, paddingBottom: 4 }}
                      className="py-1"
                      ref={swiperRef}
                    >
                      {candidates2.map((item, index) => (
                        <SwiperSlide key={index} className="me-2">
                          <CardCandidate item={item} image={item.image} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>

              <div
                className={`${
                  isMobile ? "w-full mt-10" : "w-50"
                } flex flex-col items-start justify-start`}
              >
                <div className="">
                  <div className="text-18 font-bold h-14">
                    {t("Take your job search in Japan to the next level.")}
                  </div>
                  <div className="text-md mb-8">
                    {t(
                      "A standard membership gets you started, but a Premium Membership gives you the edge you need to stand out from the crowd and land your dream job. Here's what you'll gain"
                    )}
                  </div>
                </div>
                <div className={`bg-white radius-md p-2 w-full ${isLarge && "mt-10"}`}>
                  <div className="flex items-center justify-start gap-x-4 w-full">
                    <img src={directchat} />
                    <div className="text-lg font-bold">
                      {t("Direct Chat with Employers & Admin")}
                    </div>
                  </div>
                  {directData.map((item, index) => (
                    <div key={index} className="flex items-start mt-2 justify-start gap-x-4 mb-2">
                      <img style={{ marginTop: 8 }} src={checklist} />
                      <div className="text-md">{t(item.item)}</div>
                    </div>
                  ))}

                  <div className="bg-gray-200 radius-md p-2 mt-10">
                    <div
                      style={{ borderBottom: "1px solid #ccc", paddingBottom: 14 }}
                      className="flex items-center gap-x-2"
                    >
                      <div>
                        <img
                          style={{ height: 60, width: 60, borderRadius: 100, objectFit: "cover" }}
                          src={photo}
                          alt="HR Manager"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="text-md font-bold">{t("HR Manager")}</div>
                        <div className="flex items-center gap-x-2">
                          <div
                            style={{
                              background: "#0ACD58",
                              height: 12,
                              width: 12,
                              borderRadius: 50,
                            }}
                          ></div>
                          <div className="text-md">Online</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10"></div>
                    <div className="w-full flex flex-col gap-y-7 overflow-auto">
                      <ChatBubble
                        me={true}
                        text={
                          "Hi, I'm Ayu and I'm interested in the Web Developer position. Could you tell me more about it?"
                        }
                      />
                      <ChatBubble
                        me={false}
                        text={
                          "Hallo Ayu, thanks for reaching out! We'd be happy to chat. This role involves building and maintaining user-friendly web applications for our growing e-commerce platform."
                        }
                      />
                      <ChatBubble
                        me={true}
                        text={
                          "Great! My skills in front-end development align perfectly with this role. Can I chat more about the team and company culture?"
                        }
                      />
                      <ChatBubble
                        me={false}
                        text={
                          "Sure! Our team is innovative and we value creativity in problem-solving. Is there anything else you'd like to know?"
                        }
                      />
                      <ChatBubble
                        me={true}
                        text={
                          "Thanks! I'll be submitting my application soon. Can I chat if I have questions?"
                        }
                      />
                      <ChatBubble
                        me={false}
                        text={"Absolutely! We look forward to your application."}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
        <div
          style={{ borderBottom: "1px solid #ccc", paddingBottom: 40 }}
          className="custom-container bg-gray-400"
        >
          <div
            className={`${
              isMobile
                ? "flex flex-col w-full gap-y-2 pt-10"
                : "bg-gray-400 flex flex-col items-start pt-10 justify-center"
            }`}
          >
            <div className={`w-full flex items-center justify-center flex-col`}>
              <div className="text-lg font-bold">{t("Ready to Take Control?")}</div>
              <div className="text-md w-full text-center">
                {t(
                  "Upgrade to Premium today for just IDR 50.000 per month and unlock these exclusive benefits!"
                )}
              </div>
            </div>
            <div
              style={{
                paddingLeft: "4rem",
                paddingRight: "4rem",
                paddingTop: "2rem",
                paddingBottom: "2rem",
              }}
              className="w-full bg-white mt-4 radius-sm"
            >
              <div
                className={`flex items-center justify-center gap-x-10 w-full ${
                  isMobile && "flex-col gap-y-8"
                }`}
              >
                {subscriptionPackage &&
                  subscriptionPackage.slice(0, 3)?.map((item, index) => {
                    return (
                      <CardPrice
                        onClick={() => {
                          handlePendingSubscription({ priceId: item?.id })
                        }}
                        index={index}
                        currency={pricing(item?.price).split("-")[0]}
                        price={pricing(item?.price).split("-")[1]}
                        key={index}
                        items={[
                          `Active Period ${item?.expired} Days`,
                          "Access Chat with the Company",
                          "Akses Chat with Administrator",
                          "Premium Profile Visibility",
                          "Job Recommendations",
                          "Chat Limit per Month: Unlimited",
                        ]}
                        title={item?.name || ""}
                      />
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer navigations={footerData} />
    </div>
  )
}

export default Subscription
