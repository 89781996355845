import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  value: {},
  updated: {}
}

export const seekerProfileFormSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSeekerProfileForm: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      }
    },
    changeSeekerProfileForm: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      }
      state.updated = {
        ...state.updated,
        ...action.payload
      }
    },
    setUpdatedSeekerProfileForm: (state, action) => {
      state.updated = {
        ...state.value,
        ...action.payload
      }
    },
    clearSeekerProfileForm: (state) => {
      state.value = initialState.value
    }
  }
})

export const {
  changeSeekerProfileForm,
  setSeekerProfileForm,
  setUpdatedSeekerProfileForm,
  clearSeekerProfileForm
} = seekerProfileFormSlice.actions

export default seekerProfileFormSlice.reducer
