import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

const defaultPath = 'admin/companies'

export const adminCompanyApi = createApi({
  reducerPath: 'company',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['JobPost'],
  endpoints: (builder) => ({
    getCompanies: builder.mutation({
      query: (search) => ({
        url: `${defaultPath}?search=${search ?? ''}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetCompanies'],
      transformResponse: (response) => response.results
    }),
    getCompanyByUserId: builder.mutation({
      query: (id) => ({
        url: `${defaultPath}/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetCompaniesByUserId'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetCompaniesMutation,
  useGetCompanyByUserIdMutation,
  useGetMyCompanyMutation
} = adminCompanyApi
