// ** React Imports
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useGetProfileMutation } from "../../../../redux/auth/authService"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Third Party Components
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"
import ModalAlert from "@shared/modals/ModalAlert"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg"

const UserDropdown = () => {
  const navigate = useNavigate()
  const [getProfile, { data, error, isLoading }] = useGetProfileMutation()
  const [openDialog, setOpenDialog] = useState()

  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    if (error) {
      setOpenDialog(error.message !== null)
    }
  }, [error])

  const handleClose = () => {
    setOpenDialog(false)
    localStorage.clear()
    navigate('/login', { replace: true })
  }

  return (
    <>

      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link"
          onClick={(e) => e.preventDefault()}
        >
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name fw-bold">{error ? 'Error' : isLoading ? '...Loading' : data ? `${data.firstName} ${data.lastName}` : ''}</span>
            <span className="user-status">Admin</span>
          </div>
          <Avatar
            img={defaultAvatar}
            imgHeight="40"
            imgWidth="40"
            status="online"
          />
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
            <User size={14} className="me-75" />
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
            <Mail size={14} className="me-75" />
            <span className="align-middle">Inbox</span>
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
            <CheckSquare size={14} className="me-75" />
            <span className="align-middle">Tasks</span>
          </DropdownItem>
          <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
            <MessageSquare size={14} className="me-75" />
            <span className="align-middle">Chats</span>
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem
            tag={Link}
            to="/pages/"
            onClick={(e) => e.preventDefault()}
          >
            <Settings size={14} className="me-75" />
            <span className="align-middle">Settings</span>
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
            <CreditCard size={14} className="me-75" />
            <span className="align-middle">Pricing</span>
          </DropdownItem>
          <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
            <HelpCircle size={14} className="me-75" />
            <span className="align-middle">FAQ</span>
          </DropdownItem> */}
          <DropdownItem onClick={() => {
            localStorage.clear()
            navigate('/login')
          }}>
            <Power size={14} className="me-75" />
            <span className="align-middle">Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <ModalAlert
        isOpen={openDialog}
        title="Login Failed"
        onClick={handleClose}
        closeText="Login">
        <p>{error?.data?.message}</p>
      </ModalAlert>
    </>

  )
}

export default UserDropdown
