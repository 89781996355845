import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CardBody, CardHeader } from 'reactstrap'
import SpinnerComponent from '../../../@core/components/spinner/Fallback-spinner'
import { useEmployerVerifyEmailMutation } from '../../../redux/employer/auth'
import { useSeekerVerifyEmailMutation } from '../../../redux/seeker/auth'
import VerifyTokenError from '../../VerifyTokenError'

function SeekerEmailVerification() {

  const [isError, setIsError] = useState({ err: false, code: null })
  const search = useLocation().search
  const navigate = useNavigate()

  const [verifyEmail, verifyEmailResult] = useSeekerVerifyEmailMutation()

  useEffect(() => {
    const code = new URLSearchParams(search).get('code')
    if (code) {
      verifyEmail(code)
    } else {
      setIsError({ err: true, code: 404 })
    }
  }, [])

  useEffect(() => {

    if (verifyEmailResult.isError) {
      setIsError({ err: true, code: verifyEmailResult.error })
    } else if (verifyEmailResult.isSuccess) {
      navigate('/login', { replace: true })
    }
  }, [verifyEmailResult])

  if (isError.err) {
    return <VerifyTokenError type={verifyEmailResult.error} />
  }

  return (<SpinnerComponent />)
}


function EmployerEmailVerification() {

  const [isError, setIsError] = useState(false)
  const search = useLocation().search
  const navigate = useNavigate()

  const [verifyEmail, verifyEmailResult] = useEmployerVerifyEmailMutation()

  useEffect(() => {
    const code = new URLSearchParams(search).get('code')
    if (code) {
      verifyEmail(code)
    } else {
      setIsError(true)
    }
  }, [])

  useEffect(() => {
    if (verifyEmailResult.isError) {
      setIsError(true)
    } else if (verifyEmailResult.isSuccess) {
      navigate('/login', { replace: true })
    }
  }, [verifyEmailResult])

  if (isError) {
    return <CardBody>
      <CardHeader>Something went wrong!</CardHeader>
    </CardBody>
  }

  return (<SpinnerComponent />)
}

function RedirectToEmailVerification() {
  const search = useLocation().search
  const role = new URLSearchParams(search).get('role')

  if (role === "seeker") return <SeekerEmailVerification />
  else if (role === "employer") return <EmployerEmailVerification />
  else return (<CardBody>
    <CardHeader>Something went wrong!</CardHeader>
  </CardBody>)
}


export default RedirectToEmailVerification
