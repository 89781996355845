import { User } from "react-feather"

export default [
  {
    id: 0,
    title: 'For Job Seeker',
    icon: null,
    navLink: '/seeker',
    isLogged: null,
    bg: '',
    textColor: 'text-white'
  },
  {
    id: 1,
    title: 'About Us',
    icon: null,
    navLink: '/employer/about-us',
    isLogged: null,
    bg: '',
    textColor: 'text-white'
  },
  {
    id: 2,
    title: 'My Dashboard',
    icon: null,
    navLink: '/employer/dashboard',
    isLogged: true,
    bg: '',
    textColor: 'text-white' 
  },
  {
    id: 3,
    title: 'Login',
    icon: null,
    navLink: '/employer/login',
    isRight: true,
    isLogged: false,
    bg: '',
    textColor: 'text-white'
  },
  {
    id: 4,
    title: 'Register',
    icon: null,
    navLink: '/employer/register',
    isRight: true,
    isLogged: false,
    bg: '',
    textColor: 'text-white'
  },
  {
    id: 5,
    title: 'For Job seeker',
    icon: null,
    navLink: '/seeker',
    isRight: true,
    isLogged: null,
    bg: 'bg-white',
    textColor: 'text-primary'
  },
  {
    id: 6,
    title: 'Member Information',
    icon: <User/>,
    navLink: '/employer/dashboard',
    isRight: true,
    isLogged: true,
    bg: '',
    textColor: 'text-white'
  }
]
