import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig } from "../../helpers/http"

const baseUrl = 'job/stats'

export const jobStatsApi = createApi({
  reducerPath: "job/stats",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['JobStats'],
  endpoints: (builder) => ({
    getJobStats: builder.query({
      query: () => ({
        url: `${baseUrl}`,
        method: 'GET'
      }),
      providesTags: ['JobStats'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetJobStatsQuery
} = jobStatsApi
