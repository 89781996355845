import { Briefcase, Key, LogOut, User, Users } from "react-feather"

export default [
  {
    title: 'Registered Information',
    icon: <User />,
    navLink: '/employer/dashboard/profile'
  },
  {
    title: 'Registered Job List',
    icon: <Briefcase />,
    navLink: '/employer/dashboard/manage-jobs'
  },
  {
    title: 'RegisteredApplicants',
    icon: <Users />,
    navLink: '/employer/dashboard/seekers'
  },
  // {
  //   title: '仕事を投稿してください',
  //   icon: <Briefcase />,
  //   navLink: '/employer/dashboard/post-job'
  // },
  {
    title: 'Change Password',
    icon: <Key />,
    navLink: '/employer/dashboard/change-password'
  },
  {
    title: 'Logout',
    icon: <LogOut />,
    navLink: '/seeker/dashboard/logout'
  }
  // {
  //   title: 'Resume',
  //   icon: <File />,
  //   navLink: '/employer/dashboard/resume'
  // },
  // {
  //   title: 'Manage Industries',
  //   icon: <Briefcase />,
  //   navLink: '/employer/dashboard/manage-industries'
  // },
  // {
  //   title: 'Post Industries',
  //   icon: <Briefcase />,
  //   navLink: '/employer/dashboard/post-industries'
  // },
]
