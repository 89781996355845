
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'


export const seekerProfileApi = createApi({
  reducerPath: 'seeker/profile',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["Seekers"],
  endpoints: (builder) => ({
    getProfile: builder.mutation({
      query: () => ({
        url: 'seeker/profile',
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['Seekers'],
      transformResponse: (response) => response.results
    }),
    getSeekerById: builder.mutation({
      query: (id) => ({
        url: `seeker/profile/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['seeker'],
      transformResponse: (response) => response.results
    }),    
    updateProfile: builder.mutation({
      query: (body) => ({
        url: 'seeker/profile',
        method: 'put',
        headers: authHeader(),
        body
      }),
      providesTags: ['Seekers'],
      transformResponse: (response) => response.results
    })
  })
})

export const { useUpdateProfileMutation, useGetProfileMutation, useGetSeekerByIdMutation } = seekerProfileApi
