import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'

const baseurl = 'admin'

export const adminApi = createApi({
  reducerPath: 'admin',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['Administrator'],
  endpoints: (builder) => ({
    getAdministrators: builder.mutation({
      query: () => ({
        url: `${baseurl}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetAdministrators'],
      transformResponse: (response) => response.results
    }),
    getAdministratorByUserId: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetAdministratorsByUserId'],
      transformResponse: (response) => response.results
    }),
    createAdministator: builder.mutation({
      query: ({ body }) => ({
        url: `${baseurl}`,
        method: 'post',
        headers: authHeader(),
        body
      }),
      providesTags: ['CreateAdministrator'],
      transformResponse: (response) => response.results
    }),
    updateAdministator: builder.mutation({
      query: ({ id, body }) => ({
        url: `${baseurl}/${id}`,
        method: 'put',
        headers: authHeader(),
        body
      }),
      providesTags: ['UpdateAdministrator'],
      transformResponse: (response) => response.results
    }),
    deleteAdministator: builder.mutation({
      query: ({ id }) => ({
        url: `${baseurl}/${id}`,
        method: 'delete',
        headers: authHeader()
      }),
      providesTags: ['DeleteAdministrator'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetAdministratorsMutation,
  useGetAdministratorByUserIdMutation,
  useCreateAdministatorMutation,
  useUpdateAdministatorMutation,
  useDeleteAdministatorMutation
} = adminApi
