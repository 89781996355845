import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

const baseurl = 'job/apply'

export const jobApplicationApi = createApi({
  reducerPath: 'job/application',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['JobApplication'],
  endpoints: (builder) => ({
    getJobApply: builder.mutation({
      query: () => ({
        url: `${baseurl}/admin`,
        method: 'get'
      }),
      providesTags: ['GetJobApplyForAdmin'],
      transformResponse: (response) => response.results
    }),
    getJobApplyById: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetJobApply'],
      transformResponse: (response) => response.results
    }),
    getAppliedJob: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/applied/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetAppliedJob'],
      transformResponse: (response) => response.results
    }),
    postJobApply: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'post',
        headers: authHeader()
      }),
      providesTags: ['PostJobApply'],
      transformResponse: (response) => response.results
    }),
    updateJobApply: builder.mutation({
      query(data) {
        const { id, ...body } = data
        return {
          url: `${baseurl}/${id}`,
          method: 'put',
          headers: authHeader(),
          body
        }
      },
      providesTags: ['UpdateJobApply'],
      transformResponse: (response) => response.results
    }),
    deleteJobApply: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'delete',
        headers: authHeader()
      }),
      providesTags: ['DeleteJobApply'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetJobApplyMutation,
  useGetJobApplyByIdMutation,
  useGetJobApplyBySlugMutation,
  usePostJobApplyMutation,
  useGetAppliedJobMutation,
  useUpdateJobApplyMutation,
  useDeleteJobApplyMutation
} = jobApplicationApi
