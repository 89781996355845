import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'

const baseUrl = 'admin/roles'

export const roleApi = createApi({
  reducerPath: 'role',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['role'],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => ({
        url: baseUrl,
        headers: authHeader(),
        method: 'GET'
      })
    }),
    getRoleById: builder.mutation({
      query: (id) => `${baseUrl}/${id}`
    }),
    postRole: builder.mutation({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        headers: authHeader(),
        body
      }),
      transformResponse: (response) => response.result
    })
  })
})

export const { useGetRolesQuery, useGetRoleByIdMutation, usePostRoleMutation } = roleApi
