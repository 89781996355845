
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'


export const seekerLanguagesApi = createApi({
  reducerPath: 'seeker-languages',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["Seekers"],
  endpoints: (builder) => ({
    getLanguages: builder.mutation({
      query: () => ({
        url: 'seeker/languages',
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['Seekers/GetLanguages'],
      transformResponse: (response) => response.results
    }),
    getLanguage: builder.mutation({
      query: (id) => ({
        url: `seeker/languages/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['Seekers/GetLanguage'],
      transformResponse: (response) => response.results
    }),
    postLanguage: builder.mutation({
      query: (body) => ({
        url: `seeker/languages/`,
        method: 'post',
        headers: authHeader(),
        body
      }),
      providesTags: ['Seekers/PostLanguage'],
      transformResponse: (response) => response.results
    }),
    updateLanguage: builder.mutation({
      query: (data) => ({
        url: `seeker/languages/${data.id}`,
        method: 'put',
        headers: authHeader(),
        body: data.body
      }),
      providesTags: ['Seekers/UpdateLanguage'],
      transformResponse: (response) => response.results
    }),
    deleteLanguage: builder.mutation({
      query: ({ id }) => ({
        url: `seeker/languages/${id}`,
        method: 'delete',
        headers: authHeader()
      }),
      providesTags: ['Seekers/DeleteLanguage'],
      transformResponse: (response) => response.results
    })
  })
})

export const { 
  useDeleteLanguageMutation,
  useGetLanguagesMutation,
  useGetLanguageMutation,
  usePostLanguageMutation,
  useUpdateLanguageMutation
} = seekerLanguagesApi
