
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'


export const seekerExperienceApi = createApi({
  reducerPath: 'seeker/experience',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["Seekers"],
  endpoints: (builder) => ({
    getExperiences: builder.mutation({
      query: () => ({
        url: 'seeker/experience',
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['Seekers'],
      transformResponse: (response) => response.results
    }),
    updateExperience: builder.mutation({
      query: (data) => {
        console.log(data)
        return ({
          url: `seeker/experience/${data.id}`,
          method: 'put',
          headers: authHeader(),
          body: { ...data.body }
        })
      },
      providesTags: ['Seekers'],
      transformResponse: (response) => response.results
    }),
    addExperience: builder.mutation({
      query: ({ body }) => ({
        url: `seeker/experience`,
        method: 'post',
        headers: {
          ...authHeader(),
          'Content-Type': 'application/json'
        },
        body: {
          ...body
        }
      }),
      providesTags: ['Seekers'],
      transformResponse: (response) => response.results
    }),
    deleteExperience: builder.mutation({
      query: ({ id }) => ({
        url: `seeker/experience/${id}`,
        method: 'delete',
        headers: authHeader()
      }),
      providesTags: ['Seekers'],
      transformResponse: (response) => response.results
    })
  })
})

export const { useUpdateExperienceMutation, useGetExperiencesMutation, useAddExperienceMutation, useDeleteExperienceMutation } = seekerExperienceApi
