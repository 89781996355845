import { lazy } from "react"

const Jobs =  lazy(() => import("@views/Jobs/JobList"))
const JobDetail = lazy(() => import("@views/Jobs/JobDetail"))
const JobIndustries = lazy(() => import("@views/Jobs/JobIndustries"))
const JobIndustryDetail = lazy(() => import("@views/Jobs/JobIndustryDetail"))

export default [
  {
    path: "/jobs",
    element: <Jobs />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/jobs/:id",
    element: <JobDetail />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/industries",
    element: <JobIndustries />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/industries/:slug",
    element: <JobIndustryDetail />,
    meta: {
      layout: "blank"
    }
  }
]
