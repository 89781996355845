import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

const baseurl = 'educations'

export const educationsApi = createApi({
  reducerPath: 'educations',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['Educations'],
  endpoints: (builder) => ({
    getEducations: builder.mutation({
      query: () => ({
        url: `${baseurl}`,
        method: 'get'
      }),
      providesTags: ['GetEducations'],
      transformResponse: (response) => response.results
    }),
    getEducationBySlug: builder.mutation({
      query: (slug) => ({
        url: `${baseurl}/${slug}`,
        method: 'get'
      }),
      providesTags: ['GetEducationBySlug'],
      transformResponse: (response) => response.results
    }),
    postEducation: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: 'post',
        headers: {
          ...authHeader(),
          contentType: 'multipart/form-data'
        },
        body
      }),
      providesTags: ['PostEducation'],
      transformResponse: (response) => response.results
    }),
    updateEducation: builder.mutation({
      query(data) {
        const { id, ...body } = data
        return {
          url: `${baseurl}/${id}`,
          method: 'put',
          headers: authHeader(),
          body
        }
      },
      providesTags: ['UpdateEducation'],
      transformResponse: (response) => response.results
    }),
    deleteEducation: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'delete',
        headers: authHeader(),
        body
      }),
      providesTags: ['DeleteEducation'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetEducationsMutation,
  useGetEducationBySlugMutation,
  usePostEducationMutation,
  useUpdateEducationsMutation,
  useDeleteEducationsMutation
} = educationsApi
