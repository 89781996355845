import React from "react"
import icon1 from "@src/assets/images/work-process/register-seeker.svg"
import icon2 from "@src/assets/icons/chevron-right.svg"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import { useGetJobStatsQuery } from "@src/redux/job/job_stats"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const StatsV2 = () => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 1024
  const { data, isLoading } = useGetJobStatsQuery()
  const dataSeeker = 5000 + parseInt(data?.seekerStats || 0)

  const { t } = useTranslation()

  const navigate = useNavigate()
  return (
    <div className="section-full p-b30 twm-how-it-work-area mt-2">
      <div className={`custom-container`}>
        <div className={`stats-v2 ${isMobile ? "flex flex-col w-full gap-y-2" : "gap-x-4"}`}>
          <div className="flex flex-col w-full">
            <span
              className={`font-bold text-red text-center ${isMobile ? " text-24" : " text-lg"}`}
            >
              {t("Professional total support for hiring foreigners for the first time")}
            </span>
            <span
              className={`font-bold text-red text-center ${isMobile ? " text-24" : " text-lg"}`}
            >
              {t("～Recruiting foreign personnel～")}
            </span>
            <div className="flex flex-col w-full mt-5">
              <span className={`text-center text-24`}>
                {t("☑Job posting support　Find the talent your company is looking for")}
              </span>
              <span className={`text-center text-24`}>
                {t(
                  "☑Document processing support　Specialists will support you from document preparation to submission on your behalf."
                )}
              </span>
            </div>
          </div>
          <div
            className={`${
              isMobile ? "w-full" : "w-13"
            } bg-pink-500 flex items-start gap-x-4 rounded-lg p-24`}
          >
            <img src={icon1} />
            <div className={""}>
              <div className="text-lg text-white font-semibold">
                {isLoading ? "Counting . . ." : `${dataSeeker} +`}
              </div>
              {!isMobile && (
                <div>
                  {/* <div className='text-md text-white'>{t("Registered seeker")}</div> */}
                  <div className="text-md text-white">{t("Registered seeker")}</div>
                  <div className="font-thin text-white">{t("Million daily active users")}</div>
                </div>
              )}
              <div
                onClick={() => {
                  navigate("/candidate")
                }}
                className="flex items-center mt-2 gap-x-2 cursor-pointer"
              >
                <div className={`${isMobile ? "text-sm" : "text-md"} text-white`}>
                  {t("Find out more")}
                </div>
                <img src={icon2} alt="right-arrow" />
              </div>
            </div>
            {isMobile && (
              <div className="w-13 mt-1">
                <div className="text-sm text-white">{t("Registered seeker")}</div>
                <div className="font-thin text-sm text-white">
                  {t("Million daily active users")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsV2
